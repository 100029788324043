<template>
    <v-container>
        <v-overlay v-model="overlay" color="white" opacity="1" persistent>
            <v-container style="text-align: center">
                <v-progress-circular :size="50" color="#234a98" indeterminate></v-progress-circular>
                <p class="pt-2" style="font-size: 14px; color: #234a98">
                    Loading...
                </p>
            </v-container>
        </v-overlay>
        <v-dialog v-model="manageUsersDialog" max-width="1000">
            <v-card>
                <v-card-title>
                    Manage user
                </v-card-title>
                <v-card-text>
                    <v-card>
                        <v-card-title>User data</v-card-title>
                        <v-card-text>
                            <!-- <v-text-field label="Old password" v-model="oldpwd" /> -->
                            <v-text-field outlined label="Username" />
                            <v-text-field outlined label="Registration email" />
                            <v-text-field outlined label="Real name" />
                            <v-select outlined label="User group" :items="sel_items"></v-select>
                        </v-card-text>
                    </v-card>
                    <v-card class="mt-4">
                        <v-card-title>App-Menu</v-card-title>
                        <v-card-text>
                            <v-data-table v-bind:headers="menuHeaders" :items="menuItems" class=".my-data-table-class">
                                <template v-slot:item="{ item }" >
                                    <tr class=".my-data-table-class">
                                        <td class=".my-data-table-class"> {{  item.page }} </td>
                                        <td> {{ item.section}} </td>
                                        <td> <v-icon>mdi-delete </v-icon></td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                    <v-card class="mt-4">
                        <v-card-title>Buildings Permissions</v-card-title>
                        <v-card-text>
                            <v-data-table></v-data-table>
                        </v-card-text>
                    </v-card>
                    <v-card class="mt-4">
                        <v-card-title>Floors Permissions</v-card-title>
                        <v-card-text>
                            <v-data-table></v-data-table>
                        </v-card-text>
                    </v-card>
                    <v-card class="mt-4">
                        <v-card-title>Areas Permissions</v-card-title>
                        <v-card-text>
                            <v-data-table></v-data-table>
                        </v-card-text>
                    </v-card>
                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#234a98" text @click="manageUsersDialog = false">Save</v-btn>
                    <v-btn color="#234a98" text @click="manageUsersDialog = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" max-width="600">
            <v-card>
                <v-card-title>
                    Fill the fields below to change your password.
                </v-card-title>
                <v-card-text>
                    <v-text-field label="Old password" v-model="oldpwd" />
                    <v-text-field label="New password" v-model="newpwd1" />
                    <v-text-field label="Confirm new password" v-model="newpwd2" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#234a98" text @click="SaveNewPassword()">Save</v-btn>
                    <v-btn color="#234a98" text @click="dialog = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card>
            <v-data-table v-bind:headers="headers" :items="items" :hide-default-footer="true" disable-pagination
                class="elevation-1">
                <template v-slot:item="{ item }">
                    <tr>
                        <td></td>
                        <td>{{ item.email }}</td>
                        <td><v-icon
                                :style="item.Active == 0 ? 'color: red;' : 'color: green;'">mdi-checkbox-blank-circle</v-icon>
                        </td>
                        <td>{{ item.LevelName }}</td>
                        <td>{{ item.CompanyName }}</td>
                        <td>
                            <v-btn prepend-icon="mdi-cog" @click="manageUsersDialog = true">Manage User</v-btn>
                            <!-- <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" small class="mr-2" @click="editItem(item)">mdi-lock</v-icon>
                                </template>
<span>Change password</span>
</v-tooltip> -->
                            <!-- <v-icon small class="mr-2" @click="editItem(item)">mdi-delete</v-icon> -->
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
        <v-snackbar v-model="snackbar" :timeout="timeout" color="red accent-2">
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    OK
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="snackbarGreen" :timeout="timeout" color="green accent-2">
            {{ snackbarGreenText }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    OK
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            menuHeaders: [
                {text: "Page", align: "center", value: "page"},
                {text: "Section", align: "center", value: "section"},
            ],
            menuItems: [
                {page: "Dispenser", section: "SENSORS"},
                {page: "Dashboards", section: "SENSORS"}
            ],
            sel_items: [
                {text: "Youtissue Admin"},
                {text: "Distributor"},
                {text: "Client"},
                {text: "Facility Manager"},
                {text: "Cleaner"}
            ],
            manageUsersDialog: false,
            snackbar: false,
            snackbarText: '',
            snackbarGreen: false,
            snackbarGreenText: '',
            headers: [{},
            { text: "USERNAME", align: "center", value: "email" },
            { text: "ACTIVE", align: "center", value: "Active" },
            { text: "ROLE", align: "center", value: "Gruppo" },
            { text: "COMPANY", align: "center", value: "CompanyName" },
            { text: "ACTIONS", align: "center", value: "" }, {}],

            items: [],
            dialog: false,
            id_mod: 0,
            newpwd1: '',
            newpwd2: '',
            overlay: true
        }
    },
    created() {

        if (this.$store.state.company == null || this.$store.state.company == undefined || this.$store.state.company == '') {
            let token = localStorage.getItem("token");
            if (token != null && token != undefined && token != '') {
                axios.defaults.headers.common["Authorization"] = token;
                axios.post(this.$store.state.URL_REFRESH_TOKEN,
                    {

                    })
                    .then(res => {
                        if (res.data.result == "ok") {
                            this.$store.state.company = res.data.company;
                            axios.post(this.$store.state.URL_GET_USERS,
                                {
                                    company: this.$store.state.company
                                })
                                .then(res => {
                                    this.items = res.data
                                });
                            localStorage.setItem('token', res.data.token);
                            localStorage.setItem('company', res.data.company)
                            this.$store.state.menu = res.data.menu;
                            console.log(this.$store.state.menu)
                            this.$store.state.avatar = res.data.avatar;
                            this.$store.state.displayName = res.data.displayName;
                            axios.defaults.headers.common["Authorization"] = res.data.token;
                            axios.post(this.$store.state.URL_COMPANY_DATA, {
                                "Company": this.$store.state.company
                            })
                                .then(res => {
                                    this.$store.state.building = res.data
                                    this.overlay = false;

                                })
                                .catch(() => {
                                    this.overlay = false;
                                    this.$router.push({ path: "/" });
                                })
                        }
                    })
            }
        }
        else {
            axios.post(this.$store.state.URL_GET_USERS,
                {
                    company: this.$store.state.company
                })
                .then(res => {
                    this.items = res.data
                    this.overlay = false;
                });
        }
    },
    methods: {
        editItem(item) {
            this.id_mod = item.id;
            this.dialog = true;
            this.oldpwd = '';
            this.newpwd1 = '';
            this.newpwd2 = '';

        },
        SaveNewPassword() {
            //chiamata alla funzione
            axios.post(this.$store.state.URL_CHANGE_USER_PASSWORD, {
                id: this.id_mod,
                new: this.newpwd1,
                old: this.oldpwd
            })
                .then(res => {
                    if (res.data === 'updated') {
                        this.snackbarGreenText = '';
                        this.snackbarGreen = true;
                        this.snackbarGreenText = 'Password changed successfully';
                        this.dialog = false;
                    }
                    else {
                        this.snackbarText = '';
                        this.snackbarText = res.data;
                        this.snackbar = true;
                    }
                })
                .catch(() => {
                    this.snackbar = true;
                    this.testoSnackbar = 'An error occured saving your new password. Please try again or contact the support team.'
                })
        }
    }
}
</script>
<style scoped>
.my-data-table-class {
    all: unset; /* o unset, initial a seconda delle tue necessità */
}
</style>