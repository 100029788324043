<template>
    <v-container>
      <v-overlay v-model="overlay" color="white" opacity="1" persistent>
      <v-container style="text-align: center">
        <v-progress-circular
          :size="50"
          color="#234a98"
          indeterminate
        ></v-progress-circular>
        <p class="pt-2" style="font-size: 14px; color: #234a98">
          Loading...
        </p>
      </v-container>
    </v-overlay>
    <v-dialog v-model="scelta" max-width="800" persistent>
      <v-card class="mb-4" width="100%" flat>
          <v-card-title>
              Select one smart sensor to continue
          </v-card-title>
        <v-card-text>
          <v-layout class="mt-4" id="makeMeBlue">
            <v-flex xs6 sm3 md3>
              <v-select
                  v-model="buildingValue"
                  :items="building_items"
                  label="Building"
                  outlined
                  :key="b"
                  >
              </v-select>
            </v-flex>
            <v-flex xs6 sm3 md3>
              <v-select
                  class="ml-2"
                  v-model="floorValue"
                  :items="floor_items"
                  label="Floor"
                  outlined
                  :key="f"
                  :disabled="floorActive"
                  >
              </v-select>
            </v-flex>
            <v-flex xs6 sm3 md3>
              <v-select
                  class="ml-2"
                  v-model="sensorsValue"
                  :items="sensor_items"
                  label="Sensor"
                  outlined
                  :key="s"
                  :disabled="sensorActive"
                  >
              </v-select>
            </v-flex>
            <v-flex xs6 sm3 md3>
            </v-flex>
          </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="#213A8F" @click="scelta=false; $router.push({ path: `/welcome` });">BACK</v-btn>
            <v-btn text color="#213A8F" :disabled="okActive" @click="LoadData()">OK</v-btn>
          </v-card-actions>
          </v-card>
   </v-dialog >
      <v-card width="100%" flat v-show="!scelta">
        <v-card-text>
          <v-layout class="mt-4" id="changeMyColor">
            <v-flex xs6 sm3 md3>
              <v-select
                  v-model="buildingValue"
                  :items="building_items"
                  label="Building"
                  outlined
                  :key="b"
                  color="#FFFFFF"
                  >
              </v-select>
            </v-flex>
            <v-flex xs6 sm3 md3>
              <v-select
                  class="ml-2"
                  v-model="floorValue"
                  :items="floor_items"
                  label="Floor"
                  outlined
                  :key="f"
                  :disabled="floorActive"
                  >
              </v-select>
            </v-flex>
            <v-flex xs6 sm3 md3>
              <v-select
                  class="ml-2"
                  v-model="sensorsValue"
                  :items="sensor_items"
                  label="Sensor"
                  outlined
                  :key="s"
                  :disabled="sensorActive"
                  >
              </v-select>
            </v-flex>
            <v-flex xs6 sm3 md3 class="ml-2">
                <v-menu 
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    outlined
                    v-model="date"
                    label="Select date range"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="date"
                no-title
                scrollable
                locale="it"
                range
                :max="maxDate"
                :min="minDate"
                @click="SetMinDate"
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(date); GetDataByDate()"
                >
                    OK
                </v-btn>
                </v-date-picker>
            </v-menu>
            </v-flex>
          </v-layout>
          <hr class="mt-4" style="border-top: solid 1px; color: #BFCCE4"/>
          <v-layout v-show="visualizza_dati && !scelta">
              <v-flex xs6 sm6 md3 class="mt-6 mb-4 mr-4 ml-4 justify-left">
                  <h2 style="font-weight: bold;">Quick filters: </h2>
              </v-flex>
              <v-flex xs6 sm6 md3 >
                  <v-btn text @click="ChangeDate('4h')"  class="mt-4 mb-4 mr-4 ml-4"><h2 :style="'color: ' + colore4h">| 4 HOURS |</h2></v-btn>
              </v-flex>
              <v-flex xs6 sm6 md3>
                  <v-btn text @click="ChangeDate('1')"  class="mt-4 mb-4 mr-4 ml-4"><h2 :style="'color: ' + colore1d">| 1 DAY |</h2></v-btn>
              </v-flex>
              <v-flex xs6 sm6 md3>
                  <v-btn text @click="ChangeDate('3')"  class="mt-4 mb-4 mr-4 ml-4"><h2 :style="'color: ' + colore3d">| 3 DAYS |</h2></v-btn>
              </v-flex>
              <v-flex xs6 sm6 md3>
                  <v-btn text @click="ChangeDate('7')"  class="mt-4 mb-4 mr-4 ml-4"><h2 :style="'color: ' + colore1w">| 1 WEEK |</h2></v-btn>
              </v-flex>
              <v-flex xs6 sm6 md3>
                  <v-btn text @click="ChangeDate('14')"  class="mt-4 mb-4 mr-4 ml-4"><h2 :style="'color: ' + colore2w">| 2 WEEKS |</h2></v-btn>
              </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
      <hr style="border-top: solid 1px; color: #BFCCE4"/>
      <h2 class="mt-4">{{ sensor_name }}</h2>
      <hr class="mt-4" style="border-top: solid 1px; color: #BFCCE4"/>
      <v-card  class="mt-4" height="100%" flat v-show="visualizza_dati && !scelta">
               <v-card-text>
  
                 
              <v-layout class="mt-4 mr-4 mb-4 ml-4">
                  <v-flex xs12 sm12 md12  >
                      <v-card  class="mt-4 mr-4 mb-4 ml-4" height="100%" flat>
                            <h2 >Customer Feedback</h2>
                          <v-card-text>
                              <apexchartComponent  type="bar" height="350" :options="chartOptions_bin" :series="series_dispenser" :key="keyStacked" />
                          </v-card-text>
                      </v-card>
                  </v-flex>
              </v-layout>
               </v-card-text>
           </v-card>
    </v-container>
  </template>
  
  <script>
  import axios from "axios";
      export default{
          name: 'SmartBinView',
          data(){
              return{
                maxDate: "",
                minDate: "",
                cleaning_state: '',
                last_managed: '',
                dayli:0,
                  scelta: true,
                  visualizza_dati: false,
                  sensor_img: "",
                  sensorActive: true,
                  floorActive: true,
                  i: 0,
                  b: 0,
                  s: 0,
                  colore4h: "#DAE0EC",
                  index4h: 0,
                  colore1d: "#DAE0EC",
                  colore3d: "#DAE0EC",
                  colore1w: "#DAE0EC",
                  colore2w: "#DAE0EC",
                  colore_stato: "",
                  overlay: false,
                  building_name: "",
                  floor_name: "",
                  level_status: 0,
                  last_refill: "2023-02-01",
                  GiornoSettimana: '',
                  OraSensore: '',
                  DataSensore: '',
                  keyBar: 0,
                  keyStacked: 0,
                  radialKey: 0,
                  sensor_name: "",
                  colors: "#18ff00",
                  series_punctual: [],
                  PunctualchartOptions: {
                    colors: [
                        "#009CAB",
                        "#dae0ec",
                    ],
                    states:{
                        hover: {
                            filter: {
                                type: 'none',
                                value: 0,
                            }
                        }
                    },
                    chart: {
                        type: 'bar',
                        stacked: true,
                        //stackType: "100%",
                        offsetY: -20,
                        sparkline: {
                            enabled: true
                        }
                    },
                    dataLabels:{
                        enabled:true,
                        formatter: function(val, { seriesIndex, dataPointIndex, w }){
                            dataPointIndex;
                            if(w.config.series[seriesIndex].name != 'Empty')
                                return val
                            else
                                return ''
                        },
                    },
                    xaxis: {
                        
                        min: 0,
                    },
                    yaxis:{
                        labels: {
                            style: {
                            colors: '#BFCCE4',
                            },
                        },
                        axisTicks:{
                            colors: '#BFCCE4',
                        },
                    },
                    plotOptions: {
                        states:{
                        hover: {
                            filter: {
                                type: 'none',
                                value: 0,
                            }
                        }
                    },
                        bar: {
                          borderRadius: 4,
                          columnWidth: '100%',
                          horizontal: true,
                          startAngle: -90,
                          endAngle: 90,
                          track: {
                            background: "#e7e7e7",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                            dropShadow: {
                              enabled: true,
                              top: 2,
                              left: 0,
                              color: '#999',
                              opacity: 1,
                              blur: 2
                            }
                          },
                        },
                    },
                    fill: {
                      type: 'gradient',
                      gradient: {
                          shade: 'dark',
                          type: "horizontal",
                          shadeIntensity: 0.5,
                          inverseColors: true,
                          gradientToColors: ['#213A8F'],
                          gradientFromColors: ['#009CAB'],
                          opacityFrom: 1,
                          opacityTo: 1,
                          stops: [0, 100]
                        },
                    },
                  },
            
              
                  
                  handPaperRolls: 0,
                  foldedTowels: 0,
                  users: 0,
                  averageUsers: 0,
                  menu: false,
                  date: [],
                  floor_items: [],
                  floorValue: "",
                  buildingValue: [],
                  lista_bin: [],
                  lista_stacked: [],
                  valueBin:[],
                  valueStacked: [],
                  valueBar: [],
                  valueVuoto: [],
                  valueRotoli: [],
                  valueDispenser: [],
                  dialogBar: false,
                  dialogBin: false,
                  dialogStacked: false,
                  dialogVuoto: false,
                  dialogRotoli: false,
                  dialogDispenser: false,
                  building_items: [],
                  series_bin: [],
                  series_stacked: [],
                  series_bar: [],
                  series_dispenser:[],
                  series_rotoli: [],
                  series_vuoto:[],
                  sensor_unique: '',
                  chartOptions_bin: {
                    plotOptions: {
                        bar:{
                        columnWidth: '30%',
                        distributed: false
                    },
                },
                    states:{
                        hover: {
                            filter: {
                                type: 'none',
                                value: 0,
                            }
                        }
                    },
                    colors: ['#90ee90', "#ffd700", "#f08080"],
                    // dropShadow: {
                    //         enabled: false,
                    //         enabledOnSeries: undefined,
                    //         top: 0,
                    //         left: 0,
                    //         blur: 3,
                    //         color: '#000',
                    //         opacity: 0.35
                    //     },
                      chart: {
                      toolbar: {
                          show: false,
                      },
                      type: 'line',
                      height: 700,
                      },  
                      responsive: [{
                      breakpoint: 480,
                      options: {
                          show: false,
                          legend: {
                          position: 'bottom',
                          offsetX: -10,
                          offsetY: 0
                          }
                      }
                      }],
                      yaxis: {
                      min: 0,
                      
                      
                       labels: {
                          offset: 10,
                          style:{
                            colors: '#BFCCE4',
                          },
                          formatter: function (value) {
                              return Number(value).toFixed(0);
                        },
                       }
                      },
                      xaxis: {
                        colors: '#213A8F',
                      categories: [],
                      labels: {
                          style: {
                              colors: '#213A8F',
                              fontSize: '8px',
                              fontFamily: 'Helvetica, Arial, sans-serif',
                              fontWeight: 400,
                              cssClass: 'apexcharts-xaxis-label',
                          },
                      },
                      },
                      fill: {
                      opacity: 1
                      },
                      legend: {
                          show: false,
                      position: 'right',
                      offsetX: 0,
                      offsetY: 50
                      },
                  },
                  
                  chartOptions_stacked: {
                    states:{
                        hover: {
                            filter: {
                                type: 'none',
                                value: 0,
                            }
                        }
                    },
                    plotOptions: {
                        bar:{
                        columnWidth: '70%',
                        distributed: true
                    },
                    },
                    colors:['#008ffb'],
                    chart: {
                        
                    toolbar: {
                        show: false,
                    },
                    type: 'bar',
                    height: 350,
                    // stacked: true,
                    // stackType: '100%'
                    },  
                    yaxis:{
                        labels: {
                          style: {
                              colors: '#BFCCE4',
                              fontSize: '8px',
                              fontFamily: 'Helvetica, Arial, sans-serif',
                              fontWeight: 400,
                              cssClass: 'apexcharts-xaxis-label',
                          },
                      },
                    },
                    xaxis: {
                    categories: ['01/09/2022', '02/09/2022', '03/09/2022', '04/09/2022', '05/09/2022', '06/09/2022',
                        '07/09/2022', '08/09/2022'
                    ],
                    labels: {
                          style: {
                              colors: '#213A8F',
                              fontSize: '8px',
                              fontFamily: 'Helvetica, Arial, sans-serif',
                              fontWeight: 400,
                              cssClass: 'apexcharts-xaxis-label',
                          },
                      },
                    },
                    fill: {
                        colors: ['#BFCCE4'],
                        opacity: 1
                    },
                    legend: {
                        show: false,
                    },
                    title: {
                        text: "",
                        rotate: -90,
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            cssClass: 'apexcharts-yaxis-title',
                        },
                    },
                    rossi : [],
                    gialli : [],
                    verdi : []
      },
      crosshairs: {
                },
                  chartOptions_bar: {
                    states:{
                        hover: {
                            filter: {
                                type: 'none',
                                value: 0,
                            }
                        }
                    },
                    plotOptions: {
                        bar:{
                        columnWidth: '70%',
                        distributed: true
                    },
                    },
                    chart: {
                        toolbar: {
                        show: false,
                    },
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                    },
                    dataLabels: {
                    enabled: false
                    },
                    fill: {
                    colors: ['#BFCCE4']
                    },
                    stroke: {
                    curve: 'straight'
                    },
                    legend: {
                                show: false,
                            },
                    xaxis: {
                        categories: [],
                        labels: {
                            style: {
                            colors: '#213A8F',
                            fontSize: '8px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-label',
                            legend: {
                                show: false,
                            },
                            },
                        },
                    },
                    yaxis: {
                        labels: {
                            style: {
                            colors: '#BFCCE4',
                            },
                        },
                    },
                    colors: '#BFCCE4',
                },
                  sensor_id: "",
                  sensor_items: [],
                  sensorsValue:"",
                  okActive: true,
                  minValue: 0,
                  maxValue: 0
              }
          },
          
          watch: {
              buildingValue(value){
                  this.visualizza_dati = false;
                  this.floor_items.length = 0;
                  this.sensor_items.length = 0;
                  value.floors.forEach(el=>{
                      this.floor_items.push({text: el.FloorName, value: el})
                  })
                  this.building_name = value.BuildingName;
                  this.floorActive = false;
                  this.sensorActive = true;
                  this.sensor_name = "";
                  this.sensor_img = "";
                  this.floor_name = "";
                  this.f ++;
                  this.s ++;
              },
              floorValue(value){
                  this.visualizza_dati = false;
                  this.sensor_items.length = 0;
                  value.sensors.forEach(el=>{
                    if(el.DispenserType == "Button")
                      this.sensor_items.push({text: el.DisplayName, value: el})
                  })
                  this.floor_name = value.FloorName;
                  this.sensor_name = "";
                  this.sensor_img = "";
                  this.sensorActive = false;
                  this.sensorsValue = '';
                  this.s ++;
              },
              sensorsValue(value){
                  //this.visualizza_dati = true;
                  this.sensor_name = value.DisplayName;
                  this.sensor_img = value.DispenserImage;
                  this.sensor_unique = value.DispenserUnique;
                  this.sensor_id = value.DispenserId;
                  this.minValue = value.Min_Value;
                  this.maxValue = value.Max_Value;
                  this.okActive = false;
                  this.colore1d = "#DAE0EC"
                  this.colore4h = "#DAE0EC"
                  this.colore3d = "#DAE0EC"
                  this.colore1w = "#213A8F";
                  this.colore2w = "#DAE0EC"
                  this.date=[];
                  this.LoadData();
              }
          },
          created () {
              this.overlay = true;
              if(this.$store.state.company == null || this.$store.state.company == undefined || this.$store.state.company == ''){
                let token = localStorage.getItem("token");
                if(token != null && token != undefined && token != ''){
                    axios.defaults.headers.common["Authorization"] = token;
                    axios.post('https://2ahfbwerq7qttajotsaeim3g6i0dvrte.lambda-url.eu-central-1.on.aws/',
                    {

                    })
                    .then(res=>{
                        console.log(res.data)
                        if(res.data.result == "ok"){
                            this.$store.state.company = res.data.company;
                            localStorage.setItem('token', res.data.token);
                            localStorage.setItem('company', res.data.company)
                            this.$store.state.menu = res.data.menu;
                            console.log(this.$store.state.menu)
                            this.$store.state.avatar = res.data.avatar;
                            this.$store.state.displayName = res.data.displayName;
                            axios.defaults.headers.common["Authorization"] = res.data.token;
                            axios.post(this.$store.state.URL_COMPANY_DATA,{
                                "Company" : this.$store.state.company
                            })
                            .then(res=>{
                                this.DownloadCompanyDatas();
                                this.colore1w = "#213A8F";
                                this.$store.state.building = res.data
                            })
                            .catch(()=>{
                                this.overlay = false;
                                this.$router.push({ path: "/" });
                            })
                        }
                    })
                }
              }
              else{
                this.DownloadCompanyDatas();
                this.colore1w = "#213A8F";
              }
              

          },
          mounted() {
              //this.overlay = true;
              
              
              // this.GetDataStacked(7);
              // // this.buildingValue = this.building_items[0];
              // // this.b ++;
              // this.date.push(new Date().toISOString().toString().substr(0,10));
              // this.GetData(new Date().toISOString().toString().substr(0,10),new Date(),0)
              // this.date.push(new Date().toISOString().toString().substr(0,10));
              // this.GetSensorData(this.subtractDays(-4, new Date()).toISOString().toString().substr(0,10), this.subtractDays(1, new Date()).toISOString().toString().substr(0,10));
          },
          methods: {
            SetMinDate(){
                this.minDate = this.maxDate;
            },
            GetDataByDate(){
                this.overlay=true;
                this.GetSensorData(this.subtractDays(0, new Date(this.date[0])).toISOString().toString().substr(0,10), this.subtractDays(1, new Date(this.date[1])).toISOString().toString().substr(0,10));
            },
            DownloadSchedule(){
                axios.post(this.$store.state.URL_GETSCHEDULEDATA,{
                    "sensor": this.sensor_id
                })
                .then(res=>{
                        var csv = "SENSOR NAME;BUILDING;FLOOR;CLEANING DATE;CLEANING HOUR;CLEANER\n";
                        res.data.map(el=>{
                            csv += el.DeviceName + ";"
                            csv += this.building_name + ";"
                            csv += this.floor_name + ";"
                            csv += el.Timestamp_Gestione.split(" ")[0] != "0000-00-00" ? el.Timestamp_Gestione.split(" ")[0] +  ";": "--" + ";"
                            csv += el.Timestamp_Gestione.split(" ")[1] != "00:00:00" ? el.Timestamp_Gestione.split(" ")[1] + ";": "--"+ ";"
                            csv += el.User + "\n"

                        })

                        let csvContent = "data:text/csv;charset=utf-8," + csv;
                        var encodedUri = encodeURI(csvContent);
                        const link = document.createElement("a");
                        link.setAttribute("href", encodedUri);
                        link.setAttribute("download", "cleaning_schedule.csv");
                        link.click();

                    }
                )
            },
              DownloadCompanyDatas(){
                  axios.post(this.$store.state.URL_COMPANY_DATA,{
                      "Company" : this.$store.state.company
                  })
                  .then(res=>{
                      this.$store.state.building = res.data
                      this.building_items.length = 0;
                      this.floor_items.length = 0;
                      this.$store.state.building.buildings.forEach(el=>{
                        var found = false;
                        var c = 0;
                        el.floors.forEach(e=>{
                            e.sensors.forEach(s=>{
                                found = Object.values(s).includes("Button")
                                if(found)
                                    c++;
                                console.log(e, found)
                            })
                            
                        })
                        
                        if(c>0){
                            if(this.$store.state.company == '1')
                                this.building_items.push({text: el.BuildingName.replace('Zwetko Headquarter', 'Airport'), value: el});
                            else
                                this.building_items.push({text: el.BuildingName, value: el});
                            }
                    })
                  })
                  .catch(()=>{
                      //Redirect alla pagina di errore
                      //this.snackbar = true;
                  })
              },
              LoadData(){
                this.overlay=true;
                this.scelta=false;
                this.GetSensorData(this.subtractDays(-6, new Date()).toISOString().toString().substr(0,10), this.subtractDays(1, new Date()).toISOString().toString().substr(0,10),1);
                // axios.
                //     post(this.$store.state.URL_LASTMANAGED,{
                //         "sensor" : this.sensor_id
                //     })
                //     .then(res=>{
                //         console.log(res.data);
                //         this.last_managed = res.data[0].Timestamp_Gestione;
                //         var punctual_value = Number(res.data[0].AlertValue) - Number(res.data[0].PartialCount);
                //         punctual_value = punctual_value < 0? 0 : punctual_value;
                //         var perc = (Number(res.data[0].PartialCount * 100)) / Number(res.data[0].AlertValue);
                //         if(perc > 60){
                //           this.colore_stato = "red";
                //           this.cleaning_state = "Cleaning Required";
                //         }
                //       if (perc >= 20 && perc <= 60){
                //           this.colore_stato = "#ffac27";
                //           this.cleaning_state = "Might need clean";
                //       }
                //       if(perc < 20){
                //           this.colore_stato = "green";
                //           this.cleaning_state = "Doesn’t need clean";
                //       }
                //         this.series_punctual = [{name: "Event to the alert", data: [punctual_value]}, {name: "Event triggered", data:[Number(res.data[0].PartialCount)]}];
                //         console.log("Valore: " + punctual_value.toString())
                //         this.radialKey += 1;
                //         //this.chartOptions_stacked.plotOptions.bar.columnWidth = "15%"
                //         //this.chartOptions_bin.plotOptions.bar.columnWidth = "15%"
                //         this.GetDataStacked(1);
                //         this.date.push(new Date().toISOString().toString().substr(0,10));
                //         //this.GetData(new Date().toISOString().toString().substr(0,10),new Date(),0)
                //         this.date.push(new Date().toISOString().toString().substr(0,10));
                        
                //     })
                  
                   
                  
              },
              ChangeDate(period){
                  this.overlay = true;
                  switch(period){
                      case "4h":
                          this.GetSensorData(this.subtractHours(4, new Date()).toISOString().toString().substr(0,19), new Date().toISOString().toString().substr(0,19),1);
                          this.chartOptions_stacked.plotOptions.bar.columnWidth = "15%"
                          this.chartOptions_bar.plotOptions.bar.columnWidth = "15%"
                          this.colore4h = "#213A8F"
                          this.colore1d = "#DAE0EC"
                          this.colore3d = "#DAE0EC"
                          this.colore1w = "#DAE0EC"
                          this.colore2w = "#DAE0EC"
                          break;
                      case "1": 
                          this.GetSensorData(this.subtractDays(0, new Date()).toISOString().toString().substr(0,10), this.subtractDays(1, new Date()).toISOString().toString().substr(0,10),1);
                          this.chartOptions_stacked.plotOptions.bar.columnWidth = "15%"
                          this.chartOptions_bar.plotOptions.bar.columnWidth = "15%"
                          this.colore4h = "#DAE0EC"
                          this.colore1d = "#213A8F"
                          this.colore3d = "#DAE0EC"
                          this.colore1w = "#DAE0EC"
                          this.colore2w = "#DAE0EC"
                          break;
                      case "3":
                          this.GetSensorData(this.subtractDays(-2, new Date()).toISOString().toString().substr(0,10), this.subtractDays(1, new Date()).toISOString().toString().substr(0,10));
                          this.chartOptions_stacked.plotOptions.bar.columnWidth = "40%"
                          this.chartOptions_bar.plotOptions.bar.columnWidth = "40%"
                          this.colore3d = "#213A8F";
                          this.colore4h = "#DAE0EC"
                          this.colore1d = "#DAE0EC"
                          this.colore1w = "#DAE0EC"
                          this.colore2w = "#DAE0EC"
                          break;
                      case "7":
                          this.GetSensorData(this.subtractDays(-6, new Date()).toISOString().toString().substr(0,10), this.subtractDays(1, new Date()).toISOString().toString().substr(0,10));
                          this.chartOptions_stacked.plotOptions.bar.columnWidth = "70%"
                          this.chartOptions_bar.plotOptions.bar.columnWidth = "70%"
                          this.colore1w = "#213A8F";
                          this.colore3d = "#DAE0EC";
                          this.colore4h = "#DAE0EC"
                          this.colore1d = "#DAE0EC"
                          this.colore2w = "#DAE0EC"
                          break;
                      case "14":
                          this.GetSensorData(this.subtractDays(-13, new Date()).toISOString().toString().substr(0,10), this.subtractDays(1, new Date()).toISOString().toString().substr(0,10));
                          this.chartOptions_stacked.plotOptions.bar.columnWidth = "70%"
                          this.chartOptions_bar.plotOptions.bar.columnWidth = "70%"
                          this.colore2w = "#213A8F";
                          this.colore1w = "#DAE0EC";
                          this.colore3d = "#DAE0EC";
                          this.colore4h = "#DAE0EC"
                          this.colore1d = "#DAE0EC"
                          break;
                  }
              },
              subtractHours(numOfHours, date = new Date()) {
                  date.setHours(date.getHours() - numOfHours + 1 );
                  return date;
              },
              subtractDays(numOfDays, date = new Date()) {
              date.setDate(date.getDate() + numOfDays);
  
              return date;
              },
              GetSensorData(start, nextDay,  time=0, skip=0){
  
                  start = start.length == 10? start + " 00:00:00" : start.replace("T", " ");
                  nextDay = nextDay.length == 10? nextDay + " 00:00:00" : nextDay.replace("T", " ");
                  axios.post("https://r4obbpydxcv3jgo6ptgy6dayxi0muxiy.lambda-url.eu-central-1.on.aws/",
                  {
                      "sensor_id": this.sensor_unique,
                      "from" : start,
                      "to": nextDay
                  }
                  )
                  
                  .then(res=>{
                    this.rossi = [];
                    this.verdi = [];
                    this.gialli = [];
                    this.chartOptions_bin.xaxis.categories.length = 0;
                    this.series_dispenser.length = 0;
                    
                    for(var r in res.data){
                        console.log(res.data[r])
                        this.rossi.push(res.data[r]["red"])    
                        this.verdi.push(res.data[r]["green"])    
                        this.gialli.push(res.data[r]["yellow"])    
                        this.chartOptions_bin.xaxis.categories.push(res.data[r]["date"]);
                    }
                    
                    this.series_dispenser.push({data: this.verdi, name: "green"});
                    this.series_dispenser.push({data: this.gialli, name: "yellow"});
                    this.series_dispenser.push({data: this.rossi, name: "red"});
                    console.log(skip, time)
                      
                    //   this.series_dispenser.push({data: data_, name: this.sensor_name});
                    //   console.log(this.series_dispenser)
                      this.overlay = false;
                      this.visualizza_dati = true;
                  })
                  .catch(()=>{
                      this.loading = false;
                      this.overlay = false;
                  })
              },
              // GetData(value){
              //     var nextDay = new Date((Number(value.replaceAll("-", ""))+1).toString().substr(0,4) + "-" + 
              //                         (Number(value.replaceAll("-", ""))+1).toString().substr(4,2) + "-" +
              //                         (Number(value.replaceAll("-", ""))+1).toString().substr(6,2)).toISOString().toString().substr(0,10);
              //     var today = new Date((Number(value.replaceAll("-", ""))).toString().substr(0,4) + "-" + 
              //                         (Number(value.replaceAll("-", ""))).toString().substr(4,2) + "-" +
              //                         (Number(value.replaceAll("-", ""))).toString().substr(6,2)).toISOString().toString().substr(0,10);
              //     axios.post("https://api.akenza.io/v3/devices/" + this.sensor_id + "/query",
              //     {
              //     "limit": 1,
              //     "timestamp": {
              //         "gte": today,
              //         "lte": nextDay
              //     },
              //     "topic": "default",
              //     "skip": 0
              //     }
              //     ).
              //     then(res=> {
              //         const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
              //         const d = new Date();
              //         let day = weekday[d.getDay()];
              //         this.GiornoSettimana = day;
              //         this.OraSensore = res.data[0].timestamp.substr(11,5);
              //         this.DataSensore = res.data[0].timestamp.substr(0,10);
              //         var valore = 100-(((Number(res.data[0].data.tof_distance) -this.minValue)* 100)/this.maxValue).toFixed(0);
              //         // this.series_stacked.length = 0;
              //         if (valore < 0)
              //             valore = 0;
              //         if (valore > 100)
              //             valore = 100;
              //         if(valore > 60)
              //             this.colore_stato = "#00ff4e";
              //         if (valore >= 20 && valore <= 60)
              //             this.colore_stato = "#ffac27";
              //         if(valore < 20)
              //             this.colore_stato = "#ff2727";
              //         this.radialKey += 1;
              //         this.series_punctual = [{data: [valore]}];
              //     })
              //     .catch(err=>{
              //         console.log(err);
              //     })
              // },

              GetDataStacked(days){
                  axios.post(this.$store.state.URL_STACKED, {
                      sensor: this.sensor_unique,
                      ndays: days 
                  }).
                  then(res=> {
                      this.chartOptions_stacked.xaxis.categories.length = 0;
                      this.series_stacked.length = 0;
                      this.series_bar.length = 0;
                      var elementi_vuoto = [];
                      var elementi_pieno = [];
                      var ore_vuoto = [];
                      var categories = [];
                      var v_med = [];
                      res.data.forEach(el=>{
                          elementi_vuoto.push(el.Vuoto);
                          elementi_pieno.push(el.Pieno);
                          categories.push(el.DataRegistrazione);
                          ore_vuoto.push(el.OreVuoto);
                          v_med.push(el.Media);
                          
                      })
                      this.series_bar = [{ name: "Full",data:ore_vuoto}];
                      this.series_stacked = [{ name: "Media valore",data:v_med}];
                      this.chartOptions_stacked.xaxis.categories = categories;
                      this.chartOptions_bar.xaxis.categories = categories;
                      this.keyStacked += 1
                      this.keyBar += 1
                  })
                      
                  .catch(err=>{
                      console.log(err);
                  })
              }
              
          },
      }
  </script>
  <style scoped>
  .miochart.apexcharts-bar-area:hover { fill: #213A8F !important; }
  .apexcharts-line-area:hover { fill: #213A8F !important; }
  .v-select__selection,
    .v-select__selection--comma,
    .v-select.v-text-field input {
    color: #213A8F !important;
    }
    .v-icon.v-incon{
        color: #213A8F !important;
    }
    #changeMyColor .v-text-field--outlined fieldset {
        border: #BFCCE4 1px solid !important; 
    }
    .mt-4 mb-4 mr-4 ml-4 v-btn v-btn--text theme--light v-size--default{
        border: #213A8F 1px solid !important;
    }
  </style>
  