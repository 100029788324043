import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    URL_LOGIN : "https://jig3kimysvl6oslmepcs3l7ffu0sqgnm.lambda-url.eu-central-1.on.aws/",
    URL_ALERTS : "https://v4ymv5zrocnuip6tpubgxqpohi0bkdxd.lambda-url.eu-central-1.on.aws/",
    URL_UPDATE_ALERTS: "https://ifnmz4c3v7mmfxlitvoncdex3q0vhuup.lambda-url.eu-central-1.on.aws/",
    URL_COMPANY_DATA: "https://zw6oeyhzuynefpwvevb2retjxm0axmkj.lambda-url.eu-central-1.on.aws/",
    URL_STACKED: "https://qfledtcq7ez65gkzh36jhwryje0klgfm.lambda-url.eu-central-1.on.aws/",
    URLPOSTALERTS: "https://zhq7xnqsi5lrde6brg7p3pkcs40xwwtr.lambda-url.eu-central-1.on.aws/",
    URLGETALERTSENSORS: 'https://py7grwmsov2mug45g3wkg3qnpm0urgnc.lambda-url.eu-central-1.on.aws/',
    URLGETALERTSMANAGEMENT: 'https://p2gzlrigpczethykse3blvdogq0yrjwg.lambda-url.eu-central-1.on.aws/',
    URL_SENSORSMANAGEMENT: 'https://qcobgh2uabimrjcaqipclsb5am0ftdhr.lambda-url.eu-central-1.on.aws/',
    URL_BUILDINGSMANAGEMENT: 'https://b6vxzuizau7xjj7e3jdhbx2msa0dkesa.lambda-url.eu-central-1.on.aws/',
    URL_GETSCHEDULEDATA: 'https://lqwjex35qrcfxwxusrv5yuqbii0xyfno.lambda-url.eu-central-1.on.aws/',
    URL_LASTMANAGED: 'https://hwlr3tph56nnnhtnhiqieuwfdu0xrqul.lambda-url.eu-central-1.on.aws/',
    URL_DISPENSERTYPE: 'https://vsbirtnjtishwpvu5wik7eqpwa0caxib.lambda-url.eu-central-1.on.aws/',
    URL_GET_DISPENSER_BRAND: 'https://girzmdkh2uqns2orfrjoqitkvy0cftrv.lambda-url.eu-central-1.on.aws/',
    URL_GET_AVAILABLE_CLIENT: 'https://qe5czjdwlepb7wcmemjsjkd5ne0jckod.lambda-url.eu-central-1.on.aws/',
    URL_GET_AVAILABLE_BUILDINGS: 'https://4ci7feigznhtczixb2dxbrrvbu0dwxcj.lambda-url.eu-central-1.on.aws/',
    URL_GET_AVAILABLE_FLOORS: 'https://3vu67mxzkbrhtn3mm32rcrt7fe0qfmrl.lambda-url.eu-central-1.on.aws/',
    URL_GET_AVAILABLE_AREAS: 'https://3arg3jdxrduxtbbf3gepbqysee0wjofl.lambda-url.eu-central-1.on.aws/',
    URL_GET_DISPENSER_MODELS: 'https://girzmdkh2uqns2orfrjoqitkvy0cftrv.lambda-url.eu-central-1.on.aws/',
    URL_GET_CONSUMABLE_BRANDS: 'https://girzmdkh2uqns2orfrjoqitkvy0cftrv.lambda-url.eu-central-1.on.aws/',
    URL_GET_CONSUMABLE_MODELS: 'https://w622u7ar5k33pvbs7s6bym7gzq0lwhtu.lambda-url.eu-central-1.on.aws/',
    URL_GET_DISPENSER_CODES: 'https://jhjhbzt6xpw7ts5jjlyjeri5hy0ymmzx.lambda-url.eu-central-1.on.aws/',
    URL_GET_SENSORS_COMPANY_BUILDING_FLOOR_AREA: 'https://uy5f4jc3sybn23qpjer2jf7obi0grmok.lambda-url.eu-central-1.on.aws/',
    URL_CREATE_NEW_SENSOR: 'https://6ync47hnwmwvs542b2z6hqrpq40avdtr.lambda-url.eu-central-1.on.aws/',
    URL_DELETE_SENSOR: 'https://7jomtgfzlp4wmbajkx3wktctc40hntas.lambda-url.eu-central-1.on.aws/',
    URL_SAVE_NEW_ALERT_CONFIGURATION : 'https://6b2vrocj43a4hxbavrrmjnoomu0gkiwd.lambda-url.eu-central-1.on.aws/',
    URL_CREATE_BUILDING: 'https://bjg6cft4vxozmttiph2uxme7ya0xgdkx.lambda-url.eu-central-1.on.aws/',
    URL_CREATE_FLOOR: 'https://o5gehf4wskyguiuf6g4uufxj3i0bcqdf.lambda-url.eu-central-1.on.aws/',
    URL_CREATE_AREA: 'https://mrkwrbjxi7kqt3ywvhh5wxsktu0emonq.lambda-url.eu-central-1.on.aws/',
    URL_GET_DATA_FROM_DT: 'https://4dkmzxdyjchbvcrgopcvbodspy0woobr.lambda-url.eu-central-1.on.aws/',
    URL_REFRESH_TOKEN: 'https://gmtaejhvl5dqvydwojhs3kejwe0scanp.lambda-url.eu-central-1.on.aws/',
    URL_DELETE_ALERT_MANAGEMENT: 'https://ofaq3ugc3wd552ouffggh3rjea0sdtoy.lambda-url.eu-central-1.on.aws/',
    URL_CHANGE_USER_PASSWORD: 'https://pdal6gigiiks4zflabwicztfhy0mtvnj.lambda-url.eu-central-1.on.aws/',
    URL_GET_ALL_DATA_FROM_DYNAMO: 'https://6rqxq446dht5kzntjl3hdgqhki0oviwv.lambda-url.eu-central-1.on.aws/',
    URL_GET_DATA_FROM_DYNAMO: 'https://jtjygvwpwanqvwweuklvjv2hdy0pzurc.lambda-url.eu-central-1.on.aws/',
    URL_GET_WEEKLY_CONSUMPTION: 'https://bubmeqgyv47tzbmpxisexmpgyq0lldzj.lambda-url.eu-central-1.on.aws/',
    URL_GET_7_DAYS_CONSUMPTION: 'https://vkpbkyelpftiblaadg4iwxbl2a0rrscf.lambda-url.eu-central-1.on.aws/',
    URL_GET_USERS: 'https://he6wunfj3hhkluy52ky5viexge0bmzlf.lambda-url.eu-central-1.on.aws/',
    building: [],
    company : '',
    menu : [],
    avatar: '',
    displayName: '',
    sensorFiltered: '',
    floorFiltered: '',
    buildingFiltered: '',
    isFilterActive: ''
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
