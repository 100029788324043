<template>
    <v-container>
        <v-dialog persistent max-width="600" v-model="dialogAlert">
            <v-card>
                <v-card-title>
                    Please, fill the fields below
                </v-card-title>
                <v-card-text id="makeMeBlue">
                    <v-text-field label="Title" v-model="alertName" outlined color="#213A8F" maxlength="50" counter />
                    <v-select v-model="client" color="#213A8F" outlined :items="clients" label="Client"
                        prepend-inner-icon="mdi-account" append-icon="mdi-arrow-down-drop-circle-outline"
                        append-icon-color="#213A8F" required v-if="showClient" />
                    <v-layout>
                        <v-flex xs-11 md-11 sm-11>
                            <v-select v-model="building" color="#213A8F" outlined :items="buildings" label="Building"
                                prepend-inner-icon="mdi-home" append-icon="mdi-arrow-down-drop-circle-outline"
                                append-icon-color="#213A8F" v-if="showBuilding" /></v-flex>
                    </v-layout>
                    <v-layout>
                        <v-flex xs-11 md-11 sm-11>
                            <v-select v-model="floor" color="#213A8F" outlined :items="floors" label="Floor"
                                prepend-inner-icon="mdi-floor-plan" append-icon="mdi-arrow-down-drop-circle-outline"
                                append-icon-color="#213A8F" :key="f" v-if="showFloor" /></v-flex>
                    </v-layout>

                    <v-layout>
                        <v-flex xs-11 md-11 sm-11>
                            <v-select v-model="area" color="#213A8F" outlined :items="areas" label="Area"
                                prepend-inner-icon="mdi-texture-box" append-icon="mdi-arrow-down-drop-circle-outline"
                                append-icon-color="#213A8F" :key="a" v-if="showArea" /></v-flex>
                    </v-layout>
                    <!-- <v-select v-model="sensore" color="#213A8F" outlined :items="sensors" label="Select one sensor"
                        prepend-inner-icon="mdi-access-point-network" append-icon="mdi-arrow-down-drop-circle-outline"
                        append-icon-color="#213A8F" /> -->
                    <v-layout>
                        <v-flex xs-11 md-11 sm-11>
                            <v-select v-model="sensor" multiple prepend-inner-icon="mdi-access-point-network"
                                append-icon="mdi-arrow-down-drop-circle-outline" :items="filteredSensors" label="Sensor"
                                outlined v-if="showSensor">
                            </v-select>
                        </v-flex>
                    </v-layout>
                    <v-text-field v-model="email" color="#213A8F" outlined label="email (use ; to separate emails)"
                        prepend-inner-icon="mdi-email" />
                    <v-text-field v-model="emailCustomMessage" color="#213A8F" outlined
                        label="Insert your custom message here:" prepend-inner-icon="mdi-email" />
                    <!-- <v-text-field v-model="sms" color="#213A8F" outlined label="Sms number (use ; to separate phone numbers)" prepend-inner-icon="mdi-message"/> -->
                    <v-text-field v-model="alertValue" color="#213A8F" outlined label="Alert Value (%) - n. of events"
                        prepend-inner-icon="mdi-alert" />

                    <!-- <v-switch class="justify-right" v-model="active" :label="active ? 'Active' : 'Disabled'"></v-switch> -->
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="#213A8F" @click="dialogAlert = false">Cancel</v-btn>
                    <v-btn text color="#213A8F" @click="SaveData()">Save rule</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card>
            <v-card-text class="mt-4"><v-icon color="#213A8F" text
                    @click="CreateNew()">mdi-plus-circle-outline</v-icon>Create new rule</v-card-text>
            <v-card-text>
                <v-data-table v-bind:headers="headers" :items="list" :hide-default-footer="true" disable-pagination
                    class="elevation-1 mt-4">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td></td>
                            <td>{{ item.Title }}</td>
                            <td>{{ item.DisplayName }}</td>
                            <!-- <td>{{ item.PhoneNumbers }}</td> -->
                            <td>{{ item.EmailAddress }}</td>
                            <td v-if="item.Active == 0"><v-icon color="red">mdi-circle</v-icon></td>
                            <td v-if="item.Active == 1"><v-icon color="green">mdi-circle</v-icon></td>
                            <td><v-icon @click="OpenDialog(item)">mdi-pencil</v-icon><v-icon
                                    @click="DeleteAlert(item)">mdi-delete</v-icon></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            dispenserMessage: "Paper level low. Please refill promptly.",
            doorMessage: "High activity detected. Please schedule cleaning.",
            airMessage: "Air quality alert. Please verify ventilation.",
            smartButtonMessage: "Multiple negative feedbacks received. Please inspect cleanliness urgently.",
            smartBinMessage: "Waste bin full. Please empty immediately.",
            filteredSensors: [],
            showClient: true,
            emailCustomMessage: '',
            showSensor: true,
            showBuilding: false,
            showFloor: false,
            showArea: false,
            clients: [],
            client: '',
            buildings: [],
            building: '',
            floors: [],
            floor: '',
            areas: [],
            area: '',
            sensors: [],
            sensor: '',
            list: [],
            alertValue: 0,
            dialogAlert: false,
            active: true,
            alertName: "",
            email: "",
            sms: "",
            id: null,
            id_sensore: '',
            sensore: '',
            headers: [{ text: "", align: "", value: "" },
            { text: "TITLE", align: "center", value: "Title" },
            { text: "SENSOR", align: "center", value: "DisplayName" },
            // { text: "PHONE NUMBERS", align: "center", value: "PhoneNumbers" },
            { text: "EMAIL ADDRESSES", align: "center", value: "EmailAddresses" },
            { text: "ACTIVE", align: "center", value: "Active" },
            { text: "ACTIONS", align: "center", value: "" },
            { text: "", align: "center", value: "" },],
            display_name: ''

        }
    }
    ,
    created() {
        if (this.$store.state.company == null || this.$store.state.company == undefined || this.$store.state.company == '') {
            let token = localStorage.getItem("token");
            if (token != null && token != undefined && token != '') {
                axios.defaults.headers.common["Authorization"] = token;
                axios.post(this.$store.state.URL_REFRESH_TOKEN,
                    {

                    })
                    .then(res => {
                        if (res.data.result == "ok") {
                            this.$store.state.company = res.data.company;
                            localStorage.setItem('token', res.data.token);
                            localStorage.setItem('company', res.data.company)
                            this.$store.state.menu = res.data.menu;
                            console.log(this.$store.state.menu)
                            this.$store.state.avatar = res.data.avatar;
                            this.$store.state.displayName = res.data.displayName;
                            axios.defaults.headers.common["Authorization"] = res.data.token;
                            axios.post(this.$store.state.URL_COMPANY_DATA, {
                                "Company": this.$store.state.company
                            })
                                .then(() => {
                                    this.overlay = true;
                                    axios.post(this.$store.state.URLGETALERTSENSORS, {
                                        "company": this.$store.state.company
                                    }).
                                        then(res => {
                                            this.sensors.length = 0;
                                            if (res.data) {
                                                res.data.forEach(el => {
                                                    var e = {
                                                        DisplayName: el.DisplayName,
                                                        DispenserUnique: el.DispenserUnique,

                                                    }
                                                    this.sensors.push({ text: el.DisplayName, value: e });
                                                })
                                                this.GetAlertData();
                                                this.overlay = false;
                                            }
                                        })
                                        .catch(() => {
                                            this.overlay = false;
                                        })
                                    this.GetAlertData();
                                })
                                .catch(() => {
                                    this.overlay = false;
                                    this.$router.push({ path: "/" });
                                })
                        }
                    })
            }
        }
        else
            this.GetAlertData();
    },
    mounted() {
        this.overlay = true;
        axios.post(this.$store.state.URL_GET_AVAILABLE_CLIENT)
            .then(res => {
                res.data.forEach(el => {
                    var element = { text: el.CompanyName, value: el.id }
                    this.clients.push(element);

                })
            })
            .catch(() => {
                this.overlay = false;
            })
    },
    methods: {
        DeleteAlert(item) {
            axios.post(this.$store.state.URL_DELETE_ALERT_MANAGEMENT, {
                id: item.Id
            })
                .then(res => {
                    if (res.data == "ok") {
                        this.GetAlertData();
                        this.id = 0;
                        this.id_sensore = "";
                        this.alertName = "";
                        this.email = "";
                        this.sms = "";
                        this.active = true;
                        this.display_name = "";
                    }
                    else {
                        console.log(res.data);
                    }
                })
                .catch(err => {
                    console.log(err);
                })

        },
        CreateNew() {
            this.showClient = true;
            this.dialogAlert = true;
            this.id = null;
            this.id_sensore = "";
            this.alertName = "";
            this.email = "";
            this.sms = "";
            this.active = true;
            this.display_name = "";
            this.emailCustomMessage = "";
            this.client = "";
            this.buildings.length = 0;
            this.buildings = [];
            this.building = "";
            this.floors.length = 0;
            this.floors = [];
            this.floor = "";
            this.areas.length = 0;
            this.areas = [];
            this.area = "";
            this.sensors.length = 0;
            this.sensors = [];
            this.sensor = "";
            this.alertValue = 0;
        },
        GetAlertData() {
            axios.post(this.$store.state.URLGETALERTSMANAGEMENT,
                {
                    "company": this.$store.state.company
                })
                .then(res => {
                    console.log(res.data);
                    this.list = res.data;
                })
        },
        SaveData() {
            axios.post(this.$store.state.URL_SAVE_NEW_ALERT_CONFIGURATION, {
                id: this.id,
                title: this.alertName,
                sensors: this.sensor.map(sensor => sensor.id),
                emails: this.email,
                customMessage: this.emailCustomMessage,
                alertValue: this.alertValue

            })
                .then(res => {
                    if (res.data == "ok") {
                        this.dialogAlert = false;
                        this.GetAlertData();
                        this.id = null;
                        this.id_sensore = "";
                        this.alertName = "";
                        this.email = "";
                        this.sms = "";
                        this.active = true;
                        this.display_name = "";
                    }
                })
                .catch(() => {
                    console.log("c'è qualcosa che non va")
                })
        },
        OpenDialog(item) {
            console.log(item);
            this.id = item.Id;
            this.alertName = item.Title;
            this.email = item.EmailAddress;
            var el = {
                text: item.DisplayName,
                value: item,

            }
            this.filteredSensors = [];
            this.filteredSensors.length = 0;
            this.sensor = {};
            this.filteredSensors.push(el);
            this.sensor = item;
            this.showClient = false;
            this.showBuilding = false;
            this.showFloor = false;
            this.showArea = false;
            this.id_sensore = item.IdSensore;
            this.display_name = item.DisplayName;
            this.alertValue = item.AlertValue;
            this.dialogAlert = true;

        }
    },
    watch: {
        client(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.showFloor = false;
                this.showArea = false;
                this.floors.length = 0;
                this.areas.length = 0;
                this.areas = [];
                this.floors = [];
                this.showBuilding = true;
                this.buildings.length = 0;
                this.buildings = [];
                this.building = '';
                this.sensor = '';
                this.sensors = [];
                this.sensors.length = 0;
                axios.post(this.$store.state.URL_GET_AVAILABLE_BUILDINGS, {
                    companyId: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.BuildingName, value: el.id }
                            this.buildings.push(element);

                        });
                        axios.post(this.$store.state.URL_GET_SENSORS_COMPANY_BUILDING_FLOOR_AREA, {
                            companyId: value

                        })
                            .then(res => {
                                res.data.forEach(el => {
                                    var element = { text: el.DisplayName, value: el }
                                    this.filteredSensors.push(element);
                                    this.sensors.push(element);
                                });

                            })
                    })
            }
            else {
                this.showBuilding = false;
                this.buildings.length = 0;
                this.building = '';
                this.floors.length = 0;
                this.areas.length = 0;
                this.areas = [];
                this.floors = [];
                this.buildings = [];
            }
        },
        building(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.showFloor = true;
                this.floors.length = 0;
                this.floor = '';
                this.areas.length = 0;
                this.areas = [];
                this.area = '';
                this.floors = [];
                this.sensors = [];
                this.sensors.length = 0;
                this.sensor = "";
                axios.post(this.$store.state.URL_GET_AVAILABLE_FLOORS, {
                    buildingId: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.FloorName, value: el.id }
                            this.floors.push(element);
                        });
                        axios.post(this.$store.state.URL_GET_SENSORS_COMPANY_BUILDING_FLOOR_AREA, {
                            companyId: this.client,
                            buildingId: value,

                        })
                            .then(res => {
                                res.data.forEach(el => {
                                    var element = { text: el.DisplayName, value: el }
                                    this.filteredSensors.push(element);
                                    this.sensors.push(element);
                                });

                            })
                    })
            }
            else {
                this.showFloor = false;
                this.floors.length = 0;
                this.floor = '';
                this.area = '';
                this.areas = [];
                this.floors = [];
            }
        },
        filterbuilding(value) {
            if (value == "ALL") {
                this.items = this.items_all;
            }
            else {
                this.items = this.items_all.filter(p => p.BuildingName == value);
            }
        },
        floor(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.showArea = true;
                this.areas.length = 0;
                this.area = '';
                this.areas = [];
                this.sensor = '';
                this.sensors = [];
                this.sensors.length = 0;
                axios.post(this.$store.state.URL_GET_AVAILABLE_AREAS, {
                    floorId: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.AreaName, value: el.id }
                            this.areas.push(element);

                        });
                        axios.post(this.$store.state.URL_GET_SENSORS_COMPANY_BUILDING_FLOOR_AREA, {
                            companyId: this.client,
                            buildingId: this.building,
                            floorId: value,

                        })
                            .then(res => {
                                res.data.forEach(el => {
                                    var element = { text: el.DisplayName, value: el }
                                    this.filteredSensors.push(element);
                                    this.sensors.push(element);
                                });

                            })
                    })
            }
            else {
                this.showArea = false;
                this.areas.length = 0;
                this.areas = [];
                this.area = '';
            }

        },
        sensore(value) {

            this.id_sensore = value.DispenserUnique;
            this.display_name = value.DisplayName;
            if (this.id_sensore == undefined) {
                this.id_sensore = value.value.DispenserUnique;
                this.display_name = value.value.DisplayName;
            }
        },
        area(value) {
            if (value != undefined &&
                value != null &&
                value != "" &&
                value != 0
            ) {
                this.sensors.length = 0;
                this.sensor = '';
                this.sensors = [];
                this.showSensor = true;
                axios.post(this.$store.state.URL_GET_SENSORS_COMPANY_BUILDING_FLOOR_AREA, {
                    companyId: this.client,
                    buildingId: this.building,
                    floorId: this.floor,
                    areaId: value
                })
                    .then(res => {
                        res.data.forEach(el => {
                            var element = { text: el.DisplayName, value: el }
                            this.filteredSensors.push(element);
                            this.sensors.push(element);
                        });

                    })
            }
        },
        sensor(value) {
            var type = "";
            if (value.length == 0) {
                this.filteredSensors = this.sensors;
            }

            if (value.length == 1) {
                type = value[0].DispenserType;
                switch (type) {
                    case "Dispenser":
                        this.emailCustomMessage = this.dispenserMessage;
                        break;
                    case "Air":
                        this.emailCustomMessage = this.airMessage;
                        break;
                    case "Washroom":
                        this.emailCustomMessage = this.doorMessage;
                        break;
                    case "Cestino":
                        this.emailCustomMessage = this.smartBinMessage;
                        break;
                    case "Button":
                        this.emailCustomMessage = this.smartButtonMessage;
                        break;
                }
                console.log(type)
                console.log(this.emailCustomMessage)
                this.filteredSensors = this.sensors.filter(
                    (sensor) => sensor.value.DispenserType === type
                );
            }
        }
    }
}
</script>
<style scoped>
/*.v-card__subtitle, .v-card__text{
        color: #213A8F !important;
     }*/
.theme--light.v-icon {
    color: #213A8F;
}

.v-list-item__title,
.v-list-item__subtitle {
    color: #234a98 !important;
}

.v-icon.v-icon :not(.v-icon.v-icon[data-v-43d5ffb9]) {
    color: #234a98 !important;
}
</style>
