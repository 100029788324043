<template>
    <v-container>
      <v-overlay v-model="overlay" color="white" opacity="1" persistent>
      <v-container style="text-align: center">
        <v-progress-circular
          :size="50"
          color="#234a98"
          indeterminate
        ></v-progress-circular>
        <p class="pt-2" style="font-size: 14px; color: #234a98">
          Loading...
        </p>
      </v-container>
    </v-overlay>
      <v-card width="100%" flat v-show="!scelta">
        <v-card-text>
          <v-layout class="mt-4" id="changeMyColor">
            <v-flex xs6 sm3 md3>
              <v-select
                  v-model="buildingValue"
                  :items="building_items"
                  label="Building"
                  outlined
                  :key="b"
                  color="#FFFFFF"
                  >
              </v-select>
            </v-flex>
            <v-flex xs6 sm3 md3>
              <v-select
                  class="ml-2"
                  v-model="floorValue"
                  :items="floor_items"
                  label="Floor"
                  outlined
                  :key="f"
                  :disabled="floorActive"
                  >
              </v-select>
            </v-flex>
            <v-flex xs6 sm3 md3>
              <v-select
                  class="ml-2"
                  v-model="sensorsValue"
                  :items="sensor_items"
                  label="Sensor"
                  outlined
                  :key="s"
                  :disabled="sensorActive"
                  >
              </v-select>
            </v-flex>
            <v-flex xs6 sm3 md3>
            </v-flex>
          </v-layout>
          <hr class="mt-4" style="border-top: solid 1px; color: #BFCCE4"/>
          <v-layout v-show="visualizza_dati && !scelta">
              <v-flex xs6 sm6 md3 class="mt-6 mb-4 mr-4 ml-4 justify-left">
                  <h2 style="font-weight: bold;">Quick filters: </h2>
              </v-flex>
              <v-flex xs6 sm6 md3 >
                  <v-btn text @click="ChangeDate('4h')"  class="mt-4 mb-4 mr-4 ml-4"><h2 :style="'color: ' + colore4h">| 4 HOURS |</h2></v-btn>
              </v-flex>
              <v-flex xs6 sm6 md3>
                  <v-btn text @click="ChangeDate('1')"  class="mt-4 mb-4 mr-4 ml-4"><h2 :style="'color: ' + colore1d">| 1 DAY |</h2></v-btn>
              </v-flex>
              <v-flex xs6 sm6 md3>
                  <v-btn text @click="ChangeDate('3')"  class="mt-4 mb-4 mr-4 ml-4"><h2 :style="'color: ' + colore3d">| 3 DAYS |</h2></v-btn>
              </v-flex>
              <v-flex xs6 sm6 md3>
                  <v-btn text @click="ChangeDate('7')"  class="mt-4 mb-4 mr-4 ml-4"><h2 :style="'color: ' + colore1w">| 1 WEEK |</h2></v-btn>
              </v-flex>
              <v-flex xs6 sm6 md3>
                  <v-btn text @click="ChangeDate('14')"  class="mt-4 mb-4 mr-4 ml-4"><h2 :style="'color: ' + colore2w">| 2 WEEKS |</h2></v-btn>
              </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
      <hr style="border-top: solid 1px; color: #BFCCE4"/>
      <h2 class="mt-4">{{ sensor_name }}</h2>
      <hr class="mt-4" style="border-top: solid 1px; color: #BFCCE4"/>
      <v-card class="mt-4" height="100%" flat v-show="visualizza_dati && !scelta">
               <v-card-text>
  
                  <!-- <v-layout class="mt-4 mr-4 mb-4 ml-4">
                      <v-flex xs12 sm12 md6>
                          <v-card height="100%" class="mt-4 mr-4 mb-4 ml-4" flat>
                              <v-card-title>
                                  <h3>PUNCTUAL DATA</h3>
                              </v-card-title>
                              <v-layout>
                                  <v-flex sm6 md6 >
                                      <img class="mr-16 align-left" :src="sensor_img" width="50%"/>
                                  </v-flex>
                                  <v-flex sm6 md6>
                                      <v-card-text class="text-left">
                                          <h3>Sensor name:</h3> {{ sensor_name }}
                                          <h3>Building:</h3> {{ building_name }}
                                          <h3>Floor: </h3> {{ floor_name }}
                                          <h3>Level status: </h3> <v-icon :color="colore_stato">mdi-circle</v-icon>
                                          <h3>Last time empty: </h3> {{ last_refill }}
                                      </v-card-text>
                                  </v-flex>
                              </v-layout>
                          </v-card>
                      </v-flex>
                      <div style="border-left: 1px solid #BFCCE4; height: 350px;" class="mt-4"></div>
                      <v-flex xs12 sm12 md6>
                          <v-card height="100%" class="mt-4 mr-4 mb-4 ml-4" flat>
                              <v-card-title>
                                  <h3 style="color: #213A8F !important">BIN LEVEL</h3>
                              </v-card-title>
                              <v-card-text class="justify-center">
                              <v-layout>
                                  <v-flex xs12 sm12 md12 class="justify-center mt-8">
                                       <apexchartComponent type="radialBar" height="350" :options="PunctualchartOptions" :series="series_punctual" :key="radialKey"></apexchartComponent>
                                      <apexchartComponent type="bar" height="50" :options="PunctualchartOptions" :series="series_punctual"></apexchartComponent>
                                  </v-flex>
                              </v-layout>
                              <v-layout>
                                  <v-flex xs12 sm12 md12 class="text-center mt-4">
                                      <div class="mt-4"><h3>Date: {{ DataSensore }}</h3></div>
                                      <div class="mt-4"><h3>Week day: {{ GiornoSettimana }}</h3></div>
                                      <div class="mt-4"><h3>Time: {{ OraSensore }}</h3></div>
                                  </v-flex>
                              </v-layout>
                              
                              </v-card-text>
                          </v-card>
                      </v-flex>
              </v-layout > -->
              <!-- <hr class="mt-4" style="border-top: solid 1px; color: #BFCCE4"/> -->
              <v-layout class="mt-4 mr-4 mb-4 ml-4">
              <v-flex xs12 sm12 md12>
                      <v-card class="mt-4 mr-4 mb-4 ml-4" height="100%" flat>
                          <v-card-title>
                              <v-layout>
                              <!-- <v-flex xs12 sm12 md3>
                                  <v-select
                                  v-model="displayed_range"
                                  :items="to_display"
                                  label="Select displayed range"
                                  >
                                  </v-select>
                              </v-flex> -->
                              <v-flex xs12 sm12 md12>
                              
                          </v-flex>
                          </v-layout>
                          </v-card-title>
                          <v-card-text>
                              <h2 style="margin-top: 15px; font-weight: bold;  font-size: 21px; color: #234a98 !important" >BIN LEVEL </h2>
                              <apexchartComponent type="line" height="350" :options="chartOptions_bin" :series="series_dispenser" :key="keyBin"/>
                          </v-card-text>
                          <v-card-actions>
  
                          </v-card-actions>
                      </v-card>
                      </v-flex>
                  
              </v-layout>
              <hr class="mt-4" style="border-top: solid 1px; color: #BFCCE4"/>
              <v-layout class="mt-4 mr-4 mb-4 ml-4">
                  <v-flex xs12 sm12 md12  >
                      <v-card  class="mt-4 mr-4 mb-4 ml-4" height="100%" flat>
                          <v-card-text>
                              <h2 style="margin-top: 15px; font-weight: bold; color: #234a98 !important" >AVERAGE FILLING LEVEL</h2>
                              <apexchartComponent  type="bar" height="350" :options="chartOptions_stacked" :series="series_stacked" :key="keyStacked" />
                          </v-card-text>
                      </v-card>
                  </v-flex>
              </v-layout>
              <hr class="mt-4" style="border-top: solid 1px; color: #BFCCE4"/>
              <v-layout class="mt-4 mr-4 mb-4 ml-4">
                      <v-flex xs12 sm12 md12>
                      <v-card class="mt-4 mr-4 mb-4 ml-4" height="100%" flat>
                          <v-card-text>
                              <h2 style="margin-top: 15px; font-weight: bold; font-size: 16px color: #234a98 !important" >FULL HOUR(S) </h2>
                              <apexchartComponent max-width="20%" type="bar" height="350" :options="chartOptions_bar" :series="series_bar" :key="keyBar" />
                          </v-card-text>
                      </v-card>
                      </v-flex>
                  </v-layout>
              
               </v-card-text>
           </v-card>
    </v-container>
  </template>
  
  <script>
  import axios from "axios";
      export default{
          name: 'SmartBinView',
          data(){
              return{
                keyBin: 0,
                  scelta: true,
                  visualizza_dati: false,
                  sensor_img: "",
                  sensorActive: true,
                  floorActive: true,
                  i: 0,
                  b: 0,
                  s: 0,
                  colore4h: "#DAE0EC",
                  index4h: 0,
                  colore1d: "#DAE0EC",
                  colore3d: "#DAE0EC",
                  colore1w: "#DAE0EC",
                  colore2w: "#DAE0EC",
                  colore_stato: "",
                  overlay: false,
                  building_name: "",
                  floor_name: "",
                  level_status: 0,
                  last_refill: "2023-02-01",
                  GiornoSettimana: '',
                  OraSensore: '',
                  DataSensore: '',
                  keyBar: 0,
                  keyStacked: 0,
                  radialKey: 0,
                  sensor_name: "",
                  colors: "#18ff00",
                  series_punctual: [],
                  PunctualchartOptions: {
                    colors: [
                        "#009CAB",
                        "#dae0ec",
                    ],
                    states:{
                        hover: {
                            filter: {
                                type: 'none',
                                value: 0,
                            }
                        }
                    },
                    chart: {
                        type: 'bar',
                        stacked: true,
                        stackType: "100%",
                        offsetY: -20,
                        sparkline: {
                            enabled: true
                        }
                    },
                    dataLabels:{
                        enabled:true,
                        formatter: function(val, { seriesIndex, dataPointIndex, w }){
                            dataPointIndex;
                            if(w.config.series[seriesIndex].name != 'Empty')
                                return val + "%"
                            else
                                return ''
                        },
                    },
                    xaxis: {
                        max: 100,
                        min: 0,
                        labels: {
                            style: {
                            colors: '#213A8F',
                            },
                        },
                    },
                    yaxis:{
                        labels: {
                            style: {
                            colors: '#BFCCE4',
                            },
                        },
                        axisTicks:{
                            colors: '#BFCCE4',
                        },
                    },
                    plotOptions: {
                        bar: {
                          borderRadius: 4,
                          columnWidth: '10%',
                          horizontal: true,
                          startAngle: -90,
                          endAngle: 90,
                          track: {
                            background: "#e7e7e7",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                            dropShadow: {
                              enabled: true,
                              top: 2,
                              left: 0,
                              color: '#999',
                              opacity: 1,
                              blur: 2
                            }
                          },
                        },
                    },
                    fill: {
                      type: 'gradient',
                      gradient: {
                          shade: 'dark',
                          type: "horizontal",
                          shadeIntensity: 0.5,
                          inverseColors: true,
                          gradientToColors: ['#213A8F'],
                          gradientFromColors: ['#009CAB'],
                          opacityFrom: 1,
                          opacityTo: 1,
                          stops: [0, 100]
                        },
                    },
                  },
              
                  
                  handPaperRolls: 0,
                  foldedTowels: 0,
                  users: 0,
                  averageUsers: 0,
                  menu: false,
                  date: [],
                  floor_items: [],
                  floorValue: "",
                  buildingValue: [],
                  lista_bin: [],
                  lista_stacked: [],
                  valueBin:[],
                  valueStacked: [],
                  valueBar: [],
                  valueVuoto: [],
                  valueRotoli: [],
                  valueDispenser: [],
                  dialogBar: false,
                  dialogBin: false,
                  dialogStacked: false,
                  dialogVuoto: false,
                  dialogRotoli: false,
                  dialogDispenser: false,
                  building_items: [],
                  series_bin: [],
                  series_stacked: [],
                  series_bar: [],
                  series_dispenser:[],
                  series_rotoli: [],
                  series_vuoto:[],
                  sensor_unique: '',
                  chartOptions_bin: {

                    colors: ['#BFCCE4'],
                    dropShadow: {
                            enabled: false,
                            enabledOnSeries: undefined,
                            top: 0,
                            left: 0,
                            blur: 3,
                            color: '#000',
                            opacity: 0.35
                        },
                      chart: {
                      toolbar: {
                          show: false,
                      },
                      type: 'line',
                      height: 700,
                      },  
                      responsive: [{
                      breakpoint: 480,
                      options: {
                          show: false,
                          legend: {
                          position: 'bottom',
                          offsetX: -10,
                          offsetY: 0
                          }
                      }
                      }],
                      yaxis: {
                      min: 0,
                      max: 100,
                      
                       labels: {
                          offset: 10,
                          style:{
                            colors: '#BFCCE4',
                          },
                          formatter: function (value) {
                              return Number(value).toFixed(0) + "%";
                        },
                       }
                      },
                      xaxis: {
                        colors: '#213A8F',
                      categories: [],
                      labels: {
                          style: {
                              colors: '#213A8F',
                              fontSize: '8px',
                              fontFamily: 'Helvetica, Arial, sans-serif',
                              fontWeight: 400,
                              cssClass: 'apexcharts-xaxis-label',
                          },
                      },
                      },
                      fill: {
                      opacity: 1
                      },
                      legend: {
                          show: false,
                      position: 'right',
                      offsetX: 0,
                      offsetY: 50
                      },
                  },
                  
                  chartOptions_stacked: {
                    states:{
                        hover: {
                            filter: {
                                type: 'none',
                                value: 0,
                            }
                        }
                    },
                    plotOptions: {
                        bar:{
                        columnWidth: '70%',
                        distributed: true
                    },
                    },
                    colors:['#008ffb'],
                    chart: {
                        
                    toolbar: {
                        show: false,
                    },
                    type: 'bar',
                    height: 350,
                    // stacked: true,
                    // stackType: '100%'
                    },  
                    yaxis:{
                        labels: {
                          style: {
                              colors: '#BFCCE4',
                              fontSize: '8px',
                              fontFamily: 'Helvetica, Arial, sans-serif',
                              fontWeight: 400,
                              cssClass: 'apexcharts-xaxis-label',
                          },
                      },
                    },
                    xaxis: {
                    categories: ['01/09/2022', '02/09/2022', '03/09/2022', '04/09/2022', '05/09/2022', '06/09/2022',
                        '07/09/2022', '08/09/2022'
                    ],
                    labels: {
                          style: {
                              colors: '#213A8F',
                              fontSize: '8px',
                              fontFamily: 'Helvetica, Arial, sans-serif',
                              fontWeight: 400,
                              cssClass: 'apexcharts-xaxis-label',
                              
                          },
                      },
                    },
                    fill: {
                        colors: ['#BFCCE4'],
                        opacity: 1
                    },
                    legend: {
                        show: false,
                    },
                    title: {
                        text: "",
                        rotate: -90,
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            cssClass: 'apexcharts-yaxis-title',
                        },
                    }
      },
      crosshairs: {
                },
                  chartOptions_bar: {
                    states:{
                        hover: {
                            filter: {
                                type: 'none',
                                value: 0,
                            }
                        }
                    },
                    plotOptions: {
                        bar:{
                        columnWidth: '70%',
                        distributed: true
                    },
                    },
                    colors:['#008ffb'],
                    chart: {
                        
                    toolbar: {
                        show: false,
                    },
                    type: 'bar',
                    height: 350,
                    // stacked: true,
                    // stackType: '100%'
                    },  
                    yaxis:{
                        labels: {
                          style: {
                              colors: '#BFCCE4',
                              fontSize: '8px',
                              fontFamily: 'Helvetica, Arial, sans-serif',
                              fontWeight: 400,
                              cssClass: 'apexcharts-xaxis-label',
                          },
                      },
                    },
                    xaxis: {
                    categories: ['01/09/2022', '02/09/2022', '03/09/2022', '04/09/2022', '05/09/2022', '06/09/2022',
                        '07/09/2022', '08/09/2022'
                    ],
                    labels: {
                          style: {
                              colors: '#213A8F',
                              fontSize: '8px',
                              fontFamily: 'Helvetica, Arial, sans-serif',
                              fontWeight: 400,
                              cssClass: 'apexcharts-xaxis-label',
                              
                          },
                      },
                    },
                    fill: {
                        colors: ['#BFCCE4'],
                        opacity: 1
                    },
                    legend: {
                        show: false,
                    },
                    title: {
                        text: "",
                        rotate: -90,
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            cssClass: 'apexcharts-yaxis-title',
                        },
                    }
                },
                  sensor_id: "",
                  sensor_items: [],
                  sensorsValue:"",
                  okActive: true,
                  minValue: 0,
                  maxValue: 0
              }
          },
          
          watch: {
              buildingValue(value){
                  this.visualizza_dati = false;
                  this.floor_items.length = 0;
                  this.sensor_items.length = 0;
                  value.floors.forEach(el=>{
                      this.floor_items.push({text: el.FloorName, value: el})
                  })
                  if(this.floorValue == '')
                    this.floorValue = this.floor_items[0].value;
                  this.building_name = value.BuildingName;
                  this.floorActive = false;
                  this.sensorActive = true;
                  this.sensor_name = "";
                  this.sensor_img = "";
                  this.floor_name = "";
                  this.f ++;
                  this.s ++;
              },
              floorValue(value){
                  this.visualizza_dati = false;
                  this.sensor_items.length = 0;
                  value.sensors.forEach(el=>{
                    if(el.DispenserType == "Cestino")
                      this.sensor_items.push({text: el.DisplayName, value: el})
                  })
                  this.floor_name = value.FloorName;
                  this.sensor_name = "";
                  this.sensor_img = "";
                  this.sensorActive = false;
                  this.sensorsValue = this.sensor_items[0].value;
                  this.s ++;
              },
              sensorsValue(value){
                  //this.visualizza_dati = true;
                  this.sensor_name = value.DisplayName;
                  this.sensor_img = value.DispenserImage;
                  this.sensor_unique = value.DispenserUnique;
                  this.sensor_id = value.DispenserId;
                  this.minValue = value.Min_Value;
                  this.maxValue = value.Max_Value;
                  this.okActive = false;
                  this.colore4h = "#DAE0EC"
                  this.colore1d = "#213A8F"
                  this.colore3d = "#DAE0EC"
                  this.colore1w = "#DAE0EC"
                  this.colore2w = "#DAE0EC"
                  this.LoadData();
              }
          },
          created () {
              this.overlay = true;
              console.log(this.$store.state.company);
              if(this.$store.state.company == '5' || this.$store.state.company == '6' || this.$store.state.company == '7' || this.$store.state.company == '8'){
                this.$router.push({ path: "/smartdispenser" });
              }
              if(this.$store.state.company == null || this.$store.state.company == undefined || this.$store.state.company == ''){
                let token = localStorage.getItem("token");
                if(token != null && token != undefined && token != ''){
                    axios.defaults.headers.common["Authorization"] = token;
                    axios.post(this.$store.state.URL_REFRESH_TOKEN,
                    {

                    })
                    .then(res=>{
                        if(res.data.result == "ok"){
                            this.$store.state.company = res.data.company;
                            localStorage.setItem('token', res.data.token);
                            localStorage.setItem('company', res.data.company)
                            this.$store.state.menu = res.data.menu;
                            console.log(this.$store.state.menu)
                            this.$store.state.avatar = res.data.avatar;
                            this.$store.state.displayName = res.data.displayName;
                            axios.defaults.headers.common["Authorization"] = res.data.token;
                            axios.post(this.$store.state.URL_COMPANY_DATA,{
                                "Company" : this.$store.state.company
                            })
                            .then(res=>{
                                this.DownloadCompanyDatas();
                                this.colore1d = "#213A8F"
                                this.$store.state.building = res.data

                            })
                            .catch(()=>{
                                this.overlay = false;
                                this.$router.push({ path: "/" });
                            })
                        }
                    })
                }
              }
              else{
                this.DownloadCompanyDatas();
                this.colore1d = "#213A8F"
              }
              

          },
          mounted() {
              //this.overlay = true;
              
              
              // this.GetDataStacked(7);
              // // this.buildingValue = this.building_items[0];
              // // this.b ++;
              // this.date.push(new Date().toISOString().toString().substr(0,10));
              // this.GetData(new Date().toISOString().toString().substr(0,10),new Date(),0)
              // this.date.push(new Date().toISOString().toString().substr(0,10));
              // this.GetSensorData(this.subtractDays(-4, new Date()).toISOString().toString().substr(0,10), this.subtractDays(1, new Date()).toISOString().toString().substr(0,10));
          },
          methods: {
              DownloadCompanyDatas(){
                  axios.post(this.$store.state.URL_COMPANY_DATA,{
                      "Company" : this.$store.state.company
                  })
                  .then(res=>{
                      this.$store.state.building = res.data
                      this.building_items.length = 0;
                      this.floor_items.length = 0;
                      this.$store.state.building.buildings.forEach(el=>{
                        var found = false;
                        var c = 0;
                        el.floors.forEach(e=>{
                            e.sensors.forEach(s=>{
                                found = Object.values(s).includes("Cestino")
                                if(found)
                                    c++;
                                console.log(e, found)
                            })
                            
                        })
                        
                       if(c>0){
                            if(this.$store.state.company == '1')
                                this.building_items.push({text: el.BuildingName.replace('Zwetko Headquarter', 'Airport'), value: el});
                            else
                                this.building_items.push({text: el.BuildingName, value: el});
                            }
                    })
                      for(var x=0; x < this.building_items.length; x++){
                        if(this.building_items[x].value.BuildingName == "Zwetko Headquarter"){
                            this.buildingValue = this.building_items[x].value;
                            break;
                            }
                        }
                    //   this.building_items.forEach(el=>{
                    //         this.floor_items.push({text: el.FloorName, value: el})
                    //     })
                    //   this.floorValue = this.building_items[0].value.floors[0].value;
                    //   this.floor_items.forEach(el=>{
                    //         if(el.DispenserType == "Cestino")
                    //         this.sensor_items.push({text: el.DisplayName, value: el})
                    //     })
                    //   this.sensorValue = this.building_items[0].value.floors[0].value.sensors[0].value;
                      //this.LoadData();
                  })
                  .catch(()=>{
                      //Redirect alla pagina di errore
                      //this.snackbar = true;
                  })
              },
              LoadData(){
                  this.overlay=true;
                  this.scelta=false; 
                  this.chartOptions_stacked.plotOptions.bar.columnWidth = "15%"
                  this.chartOptions_bar.plotOptions.bar.columnWidth = "15%"
                  this.GetDataStacked(1);
                  this.date.push(new Date().toISOString().toString().substr(0,10));
                  //this.GetData(new Date().toISOString().toString().substr(0,10),new Date(),0)
                  this.date.push(new Date().toISOString().toString().substr(0,10));
                  this.GetSensorData(this.subtractDays(0, new Date()).toISOString().toString().substr(0,10), this.subtractDays(1, new Date()).toISOString().toString().substr(0,10),1);
              },
              ChangeDate(period){
                  this.overlay = true;
                  switch(period){
                      case "4h":
                          this.GetSensorData(this.subtractHours(4, new Date()).toISOString().toString().substr(0,19), new Date().toISOString().toString().substr(0,19),1);
                          this.GetDataStacked(1);
                          this.chartOptions_stacked.plotOptions.bar.columnWidth = "15%"
                          this.chartOptions_bar.plotOptions.bar.columnWidth = "15%"
                          this.colore4h = "#213A8F"
                          this.colore1d = "#DAE0EC"
                          this.colore3d = "#DAE0EC"
                          this.colore1w = "#DAE0EC"
                          this.colore2w = "#DAE0EC"
                          break;
                      case "1": 
                          this.GetSensorData(this.subtractDays(0, new Date()).toISOString().toString().substr(0,10), this.subtractDays(1, new Date()).toISOString().toString().substr(0,10),1);
                          this.GetDataStacked(1);
                          this.chartOptions_stacked.plotOptions.bar.columnWidth = "15%"
                          this.chartOptions_bar.plotOptions.bar.columnWidth = "15%"
                          this.colore4h = "#DAE0EC"
                          this.colore1d = "#213A8F"
                          this.colore3d = "#DAE0EC"
                          this.colore1w = "#DAE0EC"
                          this.colore2w = "#DAE0EC"
                          break;
                      case "3":
                          this.GetDataStacked(3);
                          this.GetSensorData(this.subtractDays(-2, new Date()).toISOString().toString().substr(0,10), this.subtractDays(1, new Date()).toISOString().toString().substr(0,10));
                          this.chartOptions_stacked.plotOptions.bar.columnWidth = "40%"
                          this.chartOptions_bar.plotOptions.bar.columnWidth = "40%"
                          this.colore3d = "#213A8F";
                          this.colore4h = "#DAE0EC"
                          this.colore1d = "#DAE0EC"
                          this.colore1w = "#DAE0EC"
                          this.colore2w = "#DAE0EC"
                          break;
                      case "7":
                          this.GetDataStacked(7);
                          this.GetSensorData(this.subtractDays(-6, new Date()).toISOString().toString().substr(0,10), this.subtractDays(1, new Date()).toISOString().toString().substr(0,10));
                          this.chartOptions_stacked.plotOptions.bar.columnWidth = "70%"
                          this.chartOptions_bar.plotOptions.bar.columnWidth = "70%"
                          this.colore1w = "#213A8F";
                          this.colore3d = "#DAE0EC";
                          this.colore4h = "#DAE0EC"
                          this.colore1d = "#DAE0EC"
                          this.colore2w = "#DAE0EC"
                          break;
                      case "14":
                          this.GetDataStacked(14);
                          this.GetSensorData(this.subtractDays(-13, new Date()).toISOString().toString().substr(0,10), this.subtractDays(1, new Date()).toISOString().toString().substr(0,10));
                          this.chartOptions_stacked.plotOptions.bar.columnWidth = "70%"
                          this.chartOptions_bar.plotOptions.bar.columnWidth = "70%"
                          this.colore2w = "#213A8F";
                          this.colore1w = "#DAE0EC";
                          this.colore3d = "#DAE0EC";
                          this.colore4h = "#DAE0EC"
                          this.colore1d = "#DAE0EC"
                          break;
                  }
              },
              subtractHours(numOfHours, date = new Date()) {
                  date.setHours(date.getHours() - numOfHours + 1 );
                  return date;
              },
              subtractDays(numOfDays, date = new Date()) {
              date.setDate(date.getDate() + numOfDays);
  
              return date;
              },
              GetSensorData(start, nextDay,  time=0, skip=0){
  
                  start = start.length == 10? start + " 00:00:00" : start.replace("T", " ");
                  nextDay = nextDay.length == 10? nextDay + " 00:00:00" : nextDay.replace("T", " ");
                  axios.post(this.$store.state.URL_GET_DATA_FROM_DYNAMO,
                  {
                      "sensor_id": this.sensor_unique,
                      "from" : start,
                      "to": nextDay
                  }
                  )
                  .then(res=>{
                    if(res.data.length == 0){
                        this.series_dispenser.length = 0;
                        this.chartOptions_bin.xaxis.categories.length = 0;
                        this.series_punctual.length = 0;
                        this.GiornoSettimana = "";
                        this.OraSensore = "";
                        this.DataSensore = "";
                        this.colore_stato = "grey";
                        this.keyPunctual ++;
                        this.overlay = false;
                        this.visualizza_dati = true;
                        this.keyBin ++;
                        return
                      }
                      console.log(skip);
                      this.series_dispenser.length = 0;
                      this.sensor_datas = res.data.Items;
                      this.loading = false;
                      this.series_bin.length = 0;
                      this.chartOptions_bin.xaxis.categories.length = 0;
                      var data_= [];
                      var dati = [];
                      
                      for(var i=res.data.length -1; i>=0; i--){
                        if(res.data[i].device_data.received_at != undefined)
                          dati.push(res.data[i].device_data);
                      }
                      var j = 0;
                      i = 0;
                      var precedente = 0;
                      var val_ok = false;
                      dati = dati.sort((a, b) => (a.received_at > b.received_at) ? 1 : -1)
                      console.log(dati);
                      //Recupero i valori per i punctual data
                      const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
                      const d = new Date();
                      let day = weekday[d.getDay()];
                      this.GiornoSettimana = day;
                      this.OraSensore = dati[dati.length-1].received_at.substr(11,5);
                      this.DataSensore = dati[dati.length-1].received_at.substr(0,10);
                      var valore = 100-(((Number(dati[dati.length-1].tof_distance) - this.minValue)* 100)/(this.maxValue - this.minValue)).toFixed(0);
                      // this.series_stacked.length = 0;
                      if (valore < 0)
                          valore = 0;
                      if (valore > 100)
                          valore = 100;
                      if(valore > 60)
                          this.colore_stato = "#ff2727";
                      if (valore >= 20 && valore <= 60)
                          this.colore_stato = "#ffac27";
                      if(valore < 20)
                          this.colore_stato = "#00ff4e";
                      this.radialKey += 1;
                      this.series_punctual = [{name: this.sensor_name, data: [valore]}, {name: 'Full', data: [100-valore]}];
                      dati.map(el=>{
                          var valore = 100-(((Number(el.tof_distance) - this.minValue)* 100)/(this.maxValue - this.minValue)).toFixed(0);
                          if (valore < 0)
                            valore = 0;
                         if (valore > 100)
                            valore = 100;
                          if(valore >= 0 && valore <= 100){
                              if(i != 0 && Math.abs(precedente - valore) < 5){
                                  val_ok = false;
                                  
                              }
                              else{
                                  val_ok = true;
                                  precedente = valore;
                              }
                              if (val_ok)
                                  data_.push(valore);
                              else
                                  data_.push(precedente);
                              i++
                              
                          }
                                  else 
                              data_.push(100);
                              this.chartOptions_bin.xaxis.categories.push(el.received_at.substr(0,10) + "-" + el.received_at.substr(11,5));
                            console.log(time, j)
                            //   if(time == 0){
                                
                            //     if(dati.length > 1000 ){
                            //         if(j==50 ){
                            //             this.chartOptions_bin.xaxis.categories.push(el.received_at.substr(0,10) + "-" + el.received_at.substr(11,5));
                            //             j = 1;
                            //         }
                            //         else{
                            //             this.chartOptions_bin.xaxis.categories.push("");
                            //             j++;
                            //         }
                            //     }
                                
                            //     if(dati.length > 100 && dati.length < 1001){
                            //         if(j==15 ){
                            //             this.chartOptions_bin.xaxis.categories.push(el.received_at.substr(0,10) + "-" + el.received_at.substr(11,5));
                            //             j = 1;
                            //         }
                            //         else{
                            //             this.chartOptions_bin.xaxis.categories.push("");
                            //             j++;
                            //         }
                            //         }
                            //     if(dati.length > 35 && dati.length < 101){
                            //         if(j==5 ){
                            //             this.chartOptions_bin.xaxis.categories.push(el.received_at.substr(0,10) + "-" + el.received_at.substr(11,5));
                            //             j = 1;
                            //         }
                            //         else{
                            //             this.chartOptions_bin.xaxis.categories.push("");
                            //             j++;
                            //         }
                            //         }
                            //     else{
                            //         this.chartOptions_bin.xaxis.categories.push(el.received_at.substr(0,10) + "-" + el.received_at.substr(11,5));
                            //             j = 1;
                            //     }
                            //   }
                            // if(time == 1){
                            //     if(dati.length > 30){
                            //         if(j==3){
                            //             this.chartOptions_bin.xaxis.categories.push( el.received_at.substr(11,5));
                            //             j = 1;
                            //         }
                            //         else{
                            //             this.chartOptions_bin.xaxis.categories.push("");
                            //             j++;
                            //         }
                            //     }
                            //     else{
                            //         this.chartOptions_bin.xaxis.categories.push( el.received_at.substr(11,5));
                            //             j = 1;
                            //     }
                            // }
                      })
                      this.series_dispenser.push({data: data_, name: this.sensor_name});
                      this.overlay = false;
                      this.visualizza_dati = true;
                  })
                  .catch(()=>{
                      this.loading = false;
                      this.overlay = false;
                  })
              },
              // GetData(value){
              //     var nextDay = new Date((Number(value.replaceAll("-", ""))+1).toString().substr(0,4) + "-" + 
              //                         (Number(value.replaceAll("-", ""))+1).toString().substr(4,2) + "-" +
              //                         (Number(value.replaceAll("-", ""))+1).toString().substr(6,2)).toISOString().toString().substr(0,10);
              //     var today = new Date((Number(value.replaceAll("-", ""))).toString().substr(0,4) + "-" + 
              //                         (Number(value.replaceAll("-", ""))).toString().substr(4,2) + "-" +
              //                         (Number(value.replaceAll("-", ""))).toString().substr(6,2)).toISOString().toString().substr(0,10);
              //     axios.post("https://api.akenza.io/v3/devices/" + this.sensor_id + "/query",
              //     {
              //     "limit": 1,
              //     "timestamp": {
              //         "gte": today,
              //         "lte": nextDay
              //     },
              //     "topic": "default",
              //     "skip": 0
              //     }
              //     ).
              //     then(res=> {
              //         const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
              //         const d = new Date();
              //         let day = weekday[d.getDay()];
              //         this.GiornoSettimana = day;
              //         this.OraSensore = res.data[0].timestamp.substr(11,5);
              //         this.DataSensore = res.data[0].timestamp.substr(0,10);
              //         var valore = 100-(((Number(res.data[0].data.tof_distance) -this.minValue)* 100)/this.maxValue).toFixed(0);
              //         // this.series_stacked.length = 0;
              //         if (valore < 0)
              //             valore = 0;
              //         if (valore > 100)
              //             valore = 100;
              //         if(valore > 60)
              //             this.colore_stato = "#00ff4e";
              //         if (valore >= 20 && valore <= 60)
              //             this.colore_stato = "#ffac27";
              //         if(valore < 20)
              //             this.colore_stato = "#ff2727";
              //         this.radialKey += 1;
              //         this.series_punctual = [{data: [valore]}];
              //     })
              //     .catch(err=>{
              //         console.log(err);
              //     })
              // },
              GetDataStacked(days){
                  axios.post(this.$store.state.URL_STACKED, {
                      sensor: this.sensor_unique,
                      ndays: days 
                  }).
                  then(res=> {
                      this.chartOptions_stacked.xaxis.categories.length = 0;
                      this.chartOptions_bar.xaxis.categories.length = 0;
                      this.series_stacked.length = 0;
                      this.series_bar.length = 0;
                      var elementi_vuoto = [];
                      var elementi_pieno = [];
                      var ore_vuoto = [];
                      var categories = [];
                      var v_med = [];
                      res.data.forEach(el=>{
                          elementi_vuoto.push(el.Vuoto);
                          elementi_pieno.push(el.Pieno);
                          categories.push(el.DataRegistrazione);
                          ore_vuoto.push((24-el.OreVuoto).toFixed(2));
                          v_med.push(el.Media);
                          
                      })
                      this.series_bar = [{ name: "Full",data:ore_vuoto}];
                      this.series_stacked = [{ name: "Average",data:v_med}];
                      this.chartOptions_stacked.xaxis.categories = categories;
                      this.chartOptions_bar.xaxis.categories = categories;
                      this.keyStacked += 1
                      this.keyBar += 1
                  })
                      
                  .catch(err=>{
                      console.log(err);
                  })
              }
              
          },
      }
  </script>
  <style >
  .miochart.apexcharts-bar-area:hover { fill: #213A8F !important; }
  .apexcharts-line-area:hover { fill: #213A8F !important; }
  .v-select__selection,
    .v-select__selection--comma,
    .v-select.v-text-field input {
    color: #213A8F !important;
    }
    .v-icon.v-incon{
        color: #213A8F !important;
    }
    #changeMyColor .v-text-field--outlined fieldset {
        border: #BFCCE4 1px solid !important; 
    }
    .mt-4 mb-4 mr-4 ml-4 v-btn v-btn--text theme--light v-size--default{
        border: #213A8F 1px solid !important;
    }
  </style>
  