<template>
  <v-container>
    <v-overlay v-model="overlay" color="white" opacity="1" persistent>
        <v-container style="text-align: center">
          <v-progress-circular
            :size="50"
            color="#234a98"
            indeterminate
          ></v-progress-circular>
          <p class="pt-2" style="font-size: 14px; color: #234a98">
            Loading...
          </p>
        </v-container>
      </v-overlay>
      <v-dialog v-model="scelta" max-width="800" persistent>
        <v-card class="mb-4" width="100%" flat>
            <v-card-title>
                Select one smart dispenser to continue
            </v-card-title>
          <v-card-text>
            <v-layout class="mt-4" id="makeMeBlue">
              <v-flex xs6 sm3 md3>
                <v-select
                    v-model="buildingValue"
                    :items="building_items"
                    label="Building"
                    outlined
                    :key="b"
                    >
                </v-select>
              </v-flex>
              <v-flex xs6 sm3 md3>
                <v-select
                    class="ml-2"
                    v-model="floorValue"
                    :items="floor_items"
                    label="Floor"
                    outlined
                    :key="f"
                    :disabled="floorActive"
                    >
                </v-select>
              </v-flex>
              <v-flex xs6 sm3 md3>
                <v-select
                    class="ml-2"
                    v-model="sensorsValue"
                    :items="sensor_items"
                    label="Sensor"
                    outlined
                    :key="s"
                    :disabled="sensorActive"
                    >
                </v-select>
              </v-flex>
              <v-flex xs6 sm3 md3></v-flex>
            </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="#213A8F" @click="scelta=false; $router.push({ path: `/welcome` });">BACK</v-btn>
              <v-btn text color="#213A8F" :disabled="okActive" @click="LoadData()">OK</v-btn>
            </v-card-actions>
            </v-card>
     </v-dialog >
        <v-card width="100%" flat v-show="!scelta">
          <v-card-text>
            <v-layout class="mt-4" id="changeMyColor">
              <v-flex xs6 sm3 md3>
                <v-select
                    v-model="buildingValue"
                    :items="building_items"
                    label="Building"
                    outlined
                    :key="b"
                    color="#FFFFFF"
                    >
                </v-select>
              </v-flex>
              <v-flex xs6 sm3 md3>
                <v-select
                    class="ml-2"
                    v-model="floorValue"
                    :items="floor_items"
                    label="Floor"
                    outlined
                    :key="f"
                    :disabled="floorActive"
                    >
                </v-select>
              </v-flex>
              <v-flex xs6 sm3 md3>
                <v-select
                    class="ml-2"
                    v-model="sensorsValue"
                    :items="sensor_items"
                    label="Sensor"
                    outlined
                    :key="s"
                    :disabled="sensorActive"
                    >
                </v-select>
              </v-flex>
              <v-flex xs6 sm3 md3 class="ml-2">
                <v-menu 
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    outlined
                    v-model="date"
                    label="Select date range"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="date"
                no-title
                scrollable
                locale="it"
                range
                :max="maxDate"
                :min="minDate"
                @click="SetMinDate"
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(date); /*GetDataByDate()*/"
                >
                    OK
                </v-btn>
                </v-date-picker>
            </v-menu>
            </v-flex>
            </v-layout>
            <hr class="mt-4" style="border-top: solid 1px; color: #BFCCE4"/>
            <v-layout v-show="visualizza_dati && !scelta">
                <v-flex xs6 sm6 md3 class="mt-6 mb-4 mr-4 ml-4 justify-left">
                    <h2 style="font-weight: bold;">Quick filters: </h2>
                </v-flex>
                <v-flex xs6 sm6 md3 >
                    <v-btn text @click="ChangeDate('4h')"  class="mt-4 mb-4 mr-4 ml-4"><h2 :style="'color: ' + colore4h">| 4 HOURS |</h2></v-btn>
                </v-flex>
                <v-flex xs6 sm6 md3>
                    <v-btn text @click="ChangeDate('1')"  class="mt-4 mb-4 mr-4 ml-4"><h2 :style="'color: ' + colore1d">| 1 DAY |</h2></v-btn>
                </v-flex>
                <v-flex xs6 sm6 md3>
                    <v-btn text @click="ChangeDate('3')"  class="mt-4 mb-4 mr-4 ml-4"><h2 :style="'color: ' + colore3d">| 3 DAYS |</h2></v-btn>
                </v-flex>
                <v-flex xs6 sm6 md3>
                    <v-btn text @click="ChangeDate('7')"  class="mt-4 mb-4 mr-4 ml-4"><h2 :style="'color: ' + colore1w">| 1 WEEK |</h2></v-btn>
                </v-flex>
                <v-flex xs6 sm6 md3>
                    <v-btn text @click="ChangeDate('14')"  class="mt-4 mb-4 mr-4 ml-4"><h2 :style="'color: ' + colore2w">| 2 WEEKS |</h2></v-btn>
                </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
        <hr style="border-top: solid 1px; color: #BFCCE4"/>
        <h2 class="mt-4">{{ sensor_name }}</h2>
        <hr class="mt-4" style="border-top: solid 1px; color: #BFCCE4"/>
        <v-card class="mt-4" height="100%" flat v-show="visualizza_dati && !scelta">
                 <v-card-text>
    
                    <!-- <v-layout class="mt-4 mr-4 mb-4 ml-4">
                        <v-flex xs12 sm12 md6> -->
                            <!-- <v-card height="100%" class="mt-4 mr-4 mb-4 ml-4" flat>
                                <v-card-text>
                                    <h2 class="align-center justify-center">DATA MANAGER</h2>
                                </v-card-text>
                                <v-layout>
                                    <v-flex sm6 md6 >
                                      <img class="mt-8 mr-16 align-left" :src="sensor_img" width="70%"/>
                                    </v-flex>
                                    <v-flex sm6 md6>
                                        <v-card-text class="text-left">
                                            <h3>Sensor name:</h3> {{ sensor_name }}
                                            <h3 class="mt-8">Building:</h3> {{ building_name }}
                                            <h3 class="mt-12">Floor: </h3> {{ floor_name }}
                                            <h3 class="mt-8">Level status: </h3> <v-icon :color="colore_stato">mdi-circle</v-icon> -->
                                            <!-- <h3>Last time empty: </h3> {{ last_refill }} -->
                                        <!-- </v-card-text>
                                    </v-flex>
                                </v-layout>
                            </v-card> -->
                        <!-- </v-flex>
                        <div style="border-left: 1px solid #BFCCE4; height: 350px;" class="mt-4"></div>
                        <v-flex xs12 sm12 md6>
                            <v-card height="100%" class="mt-4 mr-4 mb-4 ml-4" flat>
                                <v-card-text>
                                    <h2 style="color: #213A8F !important">AIR QUALITY</h2>
                                </v-card-text>
                                <v-card-text class="justify-center">
                                <v-layout>
                                    <v-flex xs12 sm12 md12 class="justify-center mt-8"> -->
                                        <!-- <apexchartComponent type="radialBar" height="350" :options="PunctualchartOptions" :series="series_punctual" :key="radialKey"></apexchartComponent> -->
                                        <!-- <apexchartComponent type="bar" height="50" :options="PunctualchartOptions" :series="series_punctual" :key="keyPunctual"></apexchartComponent>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex xs12 sm12 md12 class="text-center mt-4">
                                        <div class="mt-4" style="display: inline-block;"><h3 style="display: inline-block;">Date: </h3> <h4 style="display: inline-block; font-weight: normal;">{{ DataSensore }}</h4></div>
                                        <div class="mt-8"><h3 style="display: inline-block;"></h3> <h4 style="display: inline-block; font-weight: normal;">{{ GiornoSettimana }}</h4></div>
                                        <div class="mt-8"><h3 style="display: inline-block;">Time: </h3> <h4 style="display: inline-block; font-weight: normal;"> {{ OraSensore }}</h4></div>
                                    </v-flex>
                                </v-layout>
                                
                                </v-card-text>
                            </v-card> -->
                        <!-- </v-flex>
                </v-layout > -->
                <!-- <hr class="mt-4" style="border-top: solid 1px; color: #BFCCE4"/> -->
        <v-layout >
              <v-flex xs4 sm4 md4>
                <v-btn text @click="GetData('CO2')" color="#234a98" class="mt-4 mb-4 mr-4 ml-4"><h2>CO2</h2></v-btn>
              </v-flex>
              <v-flex xs4 sm4 md4>
                <v-btn text @click="GetData('HUMIDITY')" color="#234a98" class="mt-4 mb-4 mr-4 ml-4"><h2>HUMIDITY</h2></v-btn>
              </v-flex>
              <v-flex xs4 sm4 md4>
                <v-btn text @click="GetData('TEMPERATURE')" color="#234a98" class="mt-4 mb-4 mr-4 ml-4"><h2>TEMPERATURE</h2></v-btn>
              </v-flex>
              <v-flex xs4 sm4 md4>
                <v-btn text @click="GetData('PRESSURE')" color="#234a98" class="mt-4 mb-4 mr-4 ml-4"><h2>PRESSURE</h2></v-btn>
              </v-flex>
            </v-layout>
            <hr class="mt-4" style="border-top: solid 1px; color: #BFCCE4"/>
              <h2 style="margin-top: 15px;" >{{ chart_title }}</h2>
            <v-card-text>
                <apexchartComponent type="line" height="350" :options="chartOptions_bin" :series="series_bin" :key="chartk"/>
            </v-card-text>
        </v-card-text>
    </v-card>
    <v-card >
        
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
// import AnimatedNumber from "animated-number-vue";
    export default{
        
        // components: {
        //     AnimatedNumber
        // },
        name: 'SmartBinView',
        data(){
            return{
              maxDate: "",
                minDate: "",
              chartk:0,
              chart_title: '',
                visualizza_dati: true,
                overlay : false,
                show: true,
                scelta: false,
                sensor_name: "Air Quality Office",
                to_display: [{text: "Hourly", value: 0}, {text: "Daily", value: 1}, {text: "Weekly", value : 2}],
                displayed_range: "",
                sensor_datas: [],
                menu: false,
                date: [],
                floor_items: [],
                floorValue: "",
                buildingValue: [],
                lista_bin: [{text: "test Bin Sensor"}],
                valueBin:[],
                dialogBin: false,
                series_bin: [],
                
                
                chartOptions_bin: {
                    chart: {
                    toolbar: {
                        show: false,
                    },
                    type: 'line',
                    height: 650,
                    },  
                    responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                        }
                    }
                    }],
                    stroke: {
                      curve: 'smooth'
                    },
                    yaxis: {
                      title:{
                        text: '',
                        rotate: 360,
                        offsetY: -160,


                      },
                    // min: 0,
                    // max: 100,
                    
                     labels: {
                        offset: 10,
                        formatter: function (value) {
                            return Number(value).toFixed(2);
                    },
                     }
                    },
                    xaxis: {
                    categories: [
                    ],
                    labels: {
                        style: {
                            colors: [],
                            fontSize: '10px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                    },
                    },
                    fill: {
                    opacity: 1
                    },
                    legend: {
                    position: 'right',
                    offsetX: 0,
                    offsetY: 50
                    },
                },
            }
        },
        watch:{
            // date(value){
            //     console.log(value)
            //     var nextDay = "";
            //     if(value.length == 1){
            //         nextDay = new Date((Number(value[0].replaceAll("-", ""))+1).toString().substr(0,4) + "-" + 
            //                         (Number(value[0].replaceAll("-", ""))+1).toString().substr(4,2) + "-" +
            //                         (Number(value[0].replaceAll("-", ""))+1).toString().substr(6,2));
            //         console.log(nextDay)
            //         this.GetData(value[0], nextDay, 0)
            //     }
            //     else if(value.length == 2){
            //         var primo = Number(value[0].replaceAll("-",""));
            //         var secondo = Number(value[1].replaceAll("-",""));
            //         if(primo > secondo){
            //             nextDay = new Date((Number(value[0].replaceAll("-", ""))+1).toString().substr(0,4) + "-" + 
            //                         (Number(value[0].replaceAll("-", ""))+1).toString().substr(4,2) + "-" +
            //                         (Number(value[0].replaceAll("-", ""))+1).toString().substr(6,2));
            //             this.GetData(new Date(value[1]), nextDay, 1, 0)
            //         }
            //         else if(secondo > primo){
            //             nextDay = new Date((Number(value[1].replaceAll("-", ""))+1).toString().substr(0,4) + "-" + 
            //                         (Number(value[1].replaceAll("-", ""))+1).toString().substr(4,2) + "-" +
            //                         (Number(value[1].replaceAll("-", ""))+1).toString().substr(6,2));
            //             this.GetData(new Date(value[0]), nextDay, 1, 0)
            //         }
            //         else if(primo == secondo){
            //             nextDay = new Date((Number(value[0].replaceAll("-", ""))+1).toString().substr(0,4) + "-" + 
            //                         (Number(value[0].replaceAll("-", ""))+1).toString().substr(4,2) + "-" +
            //                         (Number(value[0].replaceAll("-", ""))+1).toString().substr(6,2));
            //             this.GetData(new Date(value[0]), nextDay, 1)
            //         }
            //     }

            // },
            // displayed_range(value)
            // {
            //     console.log(value)
            // }
        },
        mounted(){
            this.loading = true;
            this.displayed_range = 0;
            this.date.push(new Date().toISOString().toString().substr(0,10));
            this.GetData("CO2")
            
            
        },
        created(){
        },
        methods: {
          SetMinDate(){
                this.minDate = this.maxDate;
            },
            GetDataByDate(){
                this.overlay=true;
                this.GetSensorData(this.subtractDays(0, new Date(this.date[0])).toISOString().toString().substr(0,10), this.subtractDays(1, new Date(this.date[1])).toISOString().toString().substr(0,10));
            },
            addDays(date, days) {
                date.setDate(date.getDate() + days);
                return date;
            },
            GetData(req){

                axios.post("https://4dkmzxdyjchbvcrgopcvbodspy0woobr.lambda-url.eu-central-1.on.aws/",
                {
                "request_data": req
                }
                )
                .then(res=>{
                  switch(req){
                    case('CO2'):
                      this.chartOptions_bin.yaxis.title.text = '(ppm)';
                      this.chart_title= req + " Level"
                      break;
                    case('HUMIDITY'):
                      this.chartOptions_bin.yaxis.title.text = '(%RH)';
                      this.chart_title= req.charAt(0).toUpperCase() + req.slice(1).toLowerCase() + " Level"
                      break;
                    case('TEMPERATURE'):
                      this.chartOptions_bin.yaxis.title.text = '(°C)';
                      this.chart_title= req.charAt(0).toUpperCase() + req.slice(1).toLowerCase() + " Level"
                      break;
                    case('PRESSURE'):
                      this.chartOptions_bin.yaxis.title.text = '(mBar)';
                      this.chart_title= req.charAt(0).toUpperCase() + req.slice(1).toLowerCase() + " Level"
                      break;
                    default:
                      break;

                  }
                  this.chartk ++;
                    this.sensor_datas.length = 0;
                    this.sensor_datas = res.data;
                    this.series_bin.length = 0;
                    var data_= [];
                    var j = 1;
                    var dati = []
                    for(var x=res.data.length-1; x>-1; x--){
                      dati.push(res.data[x])
                    }
                    dati.forEach(el=>{
                      // this.chartOptions_bin.xaxis.categories.length = 0;
                      data_.push(Number(el.data).toFixed(2));
                      //this.chartOptions_bin.xaxis.categories.push(el.timestamp.substr(11,5));
                      if(j==15){
                          this.chartOptions_bin.xaxis.categories.push(el.timestamp.substr(11,5));
                          j = 1;
                      }
                      else{
                          this.chartOptions_bin.xaxis.categories.push("");
                          j++;
                      }
                      //j++;
                      
                    })
                    this.series_bin.push({data: data_, name: "Sensore"});
                })
                .catch(()=>{
                    this.loading = false;
                })
            }
        }
       
    }
    
</script>
